/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ELLCType = {
    None: 'None',
    CCorporation: 'CCorporation',
    SCorporation: 'SCorporation',
    Partnership: 'Partnership'
} as const;
export type ELLCType = typeof ELLCType[keyof typeof ELLCType];


export function ELLCTypeFromJSON(json: any): ELLCType {
    return ELLCTypeFromJSONTyped(json, false);
}

export function ELLCTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ELLCType {
    return json as ELLCType;
}

export function ELLCTypeToJSON(value?: ELLCType | null): any {
    return value as any;
}

