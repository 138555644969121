/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BidReply } from './BidReply';
import {
    BidReplyFromJSON,
    BidReplyFromJSONTyped,
    BidReplyToJSON,
} from './BidReply';
import type { BidStatus } from './BidStatus';
import {
    BidStatusFromJSON,
    BidStatusFromJSONTyped,
    BidStatusToJSON,
} from './BidStatus';
import type { DriverBid } from './DriverBid';
import {
    DriverBidFromJSON,
    DriverBidFromJSONTyped,
    DriverBidToJSON,
} from './DriverBid';
import type { ListOfLoads } from './ListOfLoads';
import {
    ListOfLoadsFromJSON,
    ListOfLoadsFromJSONTyped,
    ListOfLoadsToJSON,
} from './ListOfLoads';
import type { LoadedOrder } from './LoadedOrder';
import {
    LoadedOrderFromJSON,
    LoadedOrderFromJSONTyped,
    LoadedOrderToJSON,
} from './LoadedOrder';
import type { Tracking } from './Tracking';
import {
    TrackingFromJSON,
    TrackingFromJSONTyped,
    TrackingToJSON,
} from './Tracking';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';
import type { VehicleForBid } from './VehicleForBid';
import {
    VehicleForBidFromJSON,
    VehicleForBidFromJSONTyped,
    VehicleForBidToJSON,
} from './VehicleForBid';
import type { ViewedBid } from './ViewedBid';
import {
    ViewedBidFromJSON,
    ViewedBidFromJSONTyped,
    ViewedBidToJSON,
} from './ViewedBid';
import type { ViewedBidReplies } from './ViewedBidReplies';
import {
    ViewedBidRepliesFromJSON,
    ViewedBidRepliesFromJSONTyped,
    ViewedBidRepliesToJSON,
} from './ViewedBidReplies';

/**
 * 
 * @export
 * @interface Bid
 */
export interface Bid {
    /**
     * 
     * @type {number}
     * @memberof Bid
     */
    priceForBroker?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Bid
     */
    priceForDrive?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Bid
     */
    pricePerMileBr?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Bid
     */
    pricePerMileDr?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Bid
     */
    profit?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Bid
     */
    brokerText?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Bid
     */
    read?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof Bid
     */
    archive?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof Bid
     */
    hasNewReplies?: boolean | null;
    /**
     * 
     * @type {Date}
     * @memberof Bid
     */
    archivedDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Bid
     */
    archivedBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Bid
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Bid
     */
    createdDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Bid
     */
    lastModifiedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Bid
     */
    lastModifiedDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Bid
     */
    messageIdHeader?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Bid
     */
    statusId?: number | null;
    /**
     * 
     * @type {BidStatus}
     * @memberof Bid
     */
    status?: BidStatus;
    /**
     * 
     * @type {number}
     * @memberof Bid
     */
    vehicleForBidId?: number | null;
    /**
     * 
     * @type {VehicleForBid}
     * @memberof Bid
     */
    vehicleForBid?: VehicleForBid;
    /**
     * 
     * @type {number}
     * @memberof Bid
     */
    loadedOrderId?: number | null;
    /**
     * 
     * @type {LoadedOrder}
     * @memberof Bid
     */
    loadedOrder?: LoadedOrder;
    /**
     * 
     * @type {string}
     * @memberof Bid
     */
    userId?: string | null;
    /**
     * 
     * @type {User}
     * @memberof Bid
     */
    dispatcher?: User;
    /**
     * 
     * @type {boolean}
     * @memberof Bid
     */
    dockHigh?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof Bid
     */
    ttDrivers?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof Bid
     */
    liftGate?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof Bid
     */
    palletJack?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof Bid
     */
    driverAssist?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof Bid
     */
    fullPPE?: boolean | null;
    /**
     * 
     * @type {Date}
     * @memberof Bid
     */
    lastReplyDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof Bid
     */
    driverBidId?: number | null;
    /**
     * 
     * @type {DriverBid}
     * @memberof Bid
     */
    driverBid?: DriverBid;
    /**
     * 
     * @type {Array<BidReply>}
     * @memberof Bid
     */
    bidReplies?: Array<BidReply> | null;
    /**
     * 
     * @type {Array<ListOfLoads>}
     * @memberof Bid
     */
    listOfLoads?: Array<ListOfLoads> | null;
    /**
     * 
     * @type {Array<Tracking>}
     * @memberof Bid
     */
    trackings?: Array<Tracking> | null;
    /**
     * 
     * @type {Array<ViewedBid>}
     * @memberof Bid
     */
    viewedBids?: Array<ViewedBid> | null;
    /**
     * 
     * @type {Array<ViewedBidReplies>}
     * @memberof Bid
     */
    viewedBidReplies?: Array<ViewedBidReplies> | null;
    /**
     * 
     * @type {number}
     * @memberof Bid
     */
    id?: number;
}

/**
 * Check if a given object implements the Bid interface.
 */
export function instanceOfBid(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BidFromJSON(json: any): Bid {
    return BidFromJSONTyped(json, false);
}

export function BidFromJSONTyped(json: any, ignoreDiscriminator: boolean): Bid {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'priceForBroker': !exists(json, 'priceForBroker') ? undefined : json['priceForBroker'],
        'priceForDrive': !exists(json, 'priceForDrive') ? undefined : json['priceForDrive'],
        'pricePerMileBr': !exists(json, 'pricePerMileBr') ? undefined : json['pricePerMileBr'],
        'pricePerMileDr': !exists(json, 'pricePerMileDr') ? undefined : json['pricePerMileDr'],
        'profit': !exists(json, 'profit') ? undefined : json['profit'],
        'brokerText': !exists(json, 'brokerText') ? undefined : json['brokerText'],
        'read': !exists(json, 'read') ? undefined : json['read'],
        'archive': !exists(json, 'archive') ? undefined : json['archive'],
        'hasNewReplies': !exists(json, 'hasNewReplies') ? undefined : json['hasNewReplies'],
        'archivedDate': !exists(json, 'archivedDate') ? undefined : (json['archivedDate'] === null ? null : new Date(json['archivedDate'])),
        'archivedBy': !exists(json, 'archivedBy') ? undefined : json['archivedBy'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (json['createdDate'] === null ? null : new Date(json['createdDate'])),
        'lastModifiedBy': !exists(json, 'lastModifiedBy') ? undefined : json['lastModifiedBy'],
        'lastModifiedDate': !exists(json, 'lastModifiedDate') ? undefined : (json['lastModifiedDate'] === null ? null : new Date(json['lastModifiedDate'])),
        'messageIdHeader': !exists(json, 'messageIdHeader') ? undefined : json['messageIdHeader'],
        'statusId': !exists(json, 'statusId') ? undefined : json['statusId'],
        'status': !exists(json, 'status') ? undefined : BidStatusFromJSON(json['status']),
        'vehicleForBidId': !exists(json, 'vehicleForBidId') ? undefined : json['vehicleForBidId'],
        'vehicleForBid': !exists(json, 'vehicleForBid') ? undefined : VehicleForBidFromJSON(json['vehicleForBid']),
        'loadedOrderId': !exists(json, 'loadedOrderId') ? undefined : json['loadedOrderId'],
        'loadedOrder': !exists(json, 'loadedOrder') ? undefined : LoadedOrderFromJSON(json['loadedOrder']),
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'dispatcher': !exists(json, 'dispatcher') ? undefined : UserFromJSON(json['dispatcher']),
        'dockHigh': !exists(json, 'dockHigh') ? undefined : json['dockHigh'],
        'ttDrivers': !exists(json, 'ttDrivers') ? undefined : json['ttDrivers'],
        'liftGate': !exists(json, 'liftGate') ? undefined : json['liftGate'],
        'palletJack': !exists(json, 'palletJack') ? undefined : json['palletJack'],
        'driverAssist': !exists(json, 'driverAssist') ? undefined : json['driverAssist'],
        'fullPPE': !exists(json, 'fullPPE') ? undefined : json['fullPPE'],
        'lastReplyDate': !exists(json, 'lastReplyDate') ? undefined : (json['lastReplyDate'] === null ? null : new Date(json['lastReplyDate'])),
        'driverBidId': !exists(json, 'driverBidId') ? undefined : json['driverBidId'],
        'driverBid': !exists(json, 'driverBid') ? undefined : DriverBidFromJSON(json['driverBid']),
        'bidReplies': !exists(json, 'bidReplies') ? undefined : (json['bidReplies'] === null ? null : (json['bidReplies'] as Array<any>).map(BidReplyFromJSON)),
        'listOfLoads': !exists(json, 'listOfLoads') ? undefined : (json['listOfLoads'] === null ? null : (json['listOfLoads'] as Array<any>).map(ListOfLoadsFromJSON)),
        'trackings': !exists(json, 'trackings') ? undefined : (json['trackings'] === null ? null : (json['trackings'] as Array<any>).map(TrackingFromJSON)),
        'viewedBids': !exists(json, 'viewedBids') ? undefined : (json['viewedBids'] === null ? null : (json['viewedBids'] as Array<any>).map(ViewedBidFromJSON)),
        'viewedBidReplies': !exists(json, 'viewedBidReplies') ? undefined : (json['viewedBidReplies'] === null ? null : (json['viewedBidReplies'] as Array<any>).map(ViewedBidRepliesFromJSON)),
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function BidToJSON(value?: Bid | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'priceForBroker': value.priceForBroker,
        'priceForDrive': value.priceForDrive,
        'pricePerMileBr': value.pricePerMileBr,
        'pricePerMileDr': value.pricePerMileDr,
        'profit': value.profit,
        'brokerText': value.brokerText,
        'read': value.read,
        'archive': value.archive,
        'hasNewReplies': value.hasNewReplies,
        'archivedDate': value.archivedDate === undefined ? undefined : (value.archivedDate === null ? null : value.archivedDate.toISOString()),
        'archivedBy': value.archivedBy,
        'createdBy': value.createdBy,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate === null ? null : value.createdDate.toISOString()),
        'lastModifiedBy': value.lastModifiedBy,
        'lastModifiedDate': value.lastModifiedDate === undefined ? undefined : (value.lastModifiedDate === null ? null : value.lastModifiedDate.toISOString()),
        'messageIdHeader': value.messageIdHeader,
        'statusId': value.statusId,
        'status': BidStatusToJSON(value.status),
        'vehicleForBidId': value.vehicleForBidId,
        'vehicleForBid': VehicleForBidToJSON(value.vehicleForBid),
        'loadedOrderId': value.loadedOrderId,
        'loadedOrder': LoadedOrderToJSON(value.loadedOrder),
        'userId': value.userId,
        'dispatcher': UserToJSON(value.dispatcher),
        'dockHigh': value.dockHigh,
        'ttDrivers': value.ttDrivers,
        'liftGate': value.liftGate,
        'palletJack': value.palletJack,
        'driverAssist': value.driverAssist,
        'fullPPE': value.fullPPE,
        'lastReplyDate': value.lastReplyDate === undefined ? undefined : (value.lastReplyDate === null ? null : value.lastReplyDate.toISOString()),
        'driverBidId': value.driverBidId,
        'driverBid': DriverBidToJSON(value.driverBid),
        'bidReplies': value.bidReplies === undefined ? undefined : (value.bidReplies === null ? null : (value.bidReplies as Array<any>).map(BidReplyToJSON)),
        'listOfLoads': value.listOfLoads === undefined ? undefined : (value.listOfLoads === null ? null : (value.listOfLoads as Array<any>).map(ListOfLoadsToJSON)),
        'trackings': value.trackings === undefined ? undefined : (value.trackings === null ? null : (value.trackings as Array<any>).map(TrackingToJSON)),
        'viewedBids': value.viewedBids === undefined ? undefined : (value.viewedBids === null ? null : (value.viewedBids as Array<any>).map(ViewedBidToJSON)),
        'viewedBidReplies': value.viewedBidReplies === undefined ? undefined : (value.viewedBidReplies === null ? null : (value.viewedBidReplies as Array<any>).map(ViewedBidRepliesToJSON)),
        'id': value.id,
    };
}

