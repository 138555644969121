/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EAuthResult = {
    None: 'None',
    Ok: 'Ok',
    UserDisabled: 'UserDisabled',
    PasswordNotMatch: 'PasswordNotMatch',
    EmailAuthNotAllowed: 'EmailAuthNotAllowed',
    PhoneAuthNotAllowed: 'PhoneAuthNotAllowed',
    InvalidCode: 'InvalidCode',
    TooManyAttempts: 'TooManyAttempts',
    ExpiredCode: 'ExpiredCode'
} as const;
export type EAuthResult = typeof EAuthResult[keyof typeof EAuthResult];


export function EAuthResultFromJSON(json: any): EAuthResult {
    return EAuthResultFromJSONTyped(json, false);
}

export function EAuthResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): EAuthResult {
    return json as EAuthResult;
}

export function EAuthResultToJSON(value?: EAuthResult | null): any {
    return value as any;
}

