import React, { useCallback, useMemo } from 'react';
import { Driver, Vehicle, Request, DriversApi } from '../../../../generated-api';
import LogisticsDataGridBase from '../../../../components/data-grid/LogisticsDataGridBase';
import requestDriverListColumnDef from './RequestDriverListColumnDef';
import { useModal } from '../../../../hooks';
import DriverEdit from '../../../drivers/DriverEdit';
import { Button } from 'primereact/button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import useRights from '../../../../hooks/RightsHook';
import { logisticsConfirmDialog } from '../../../../components/LogisticsConfifmDialog';
import { apiFactory } from '../../../../shared';
import { showErrorToast } from '../../../../components/LogisticsToast';

type RequestDriverListProps = {
  drivers?: Driver[] | null | undefined,
  setDriver: (Driver: Vehicle, forDeletion?: boolean) => any,
  request: Request,
}

function RequestDriverList(props: RequestDriverListProps) {
  const driverModal = useModal<Driver>({});
  const rights = useRights(security => security.request);
  const addDriverButton = <Button
    disabled={!props.request?.id || !rights.update}
    className='p-button-success px-5 ml-2 text-base font-bold'
    onClick={() => driverModal.show({ request: props.request })}
  >
    <p className='my-0'>Add driver</p>
    <AddCircleOutlineIcon fontSize='medium' className='ml-1' />
  </Button>;

  const driverEdit = driverModal.visible
    ? <DriverEdit
      data={driverModal.data}
      loading={false}
      visible={driverModal.visible}
      openedFromRequest={true}
      hide={driver => {
        if (!driver) return driverModal.hide();

        props.setDriver(driver);
        driverModal.hide();
      }}
    />
    : <></>;

  const removeActionButton = useCallback((rowData: Driver) => <Button
    icon='pi pi-trash'
    disabled={false}
    className='p-button-lg p-button-rounded p-button-text p-button-plain hover:text-red-400'
    onClick={(e) => {
      if (!rowData?.id) return;

      logisticsConfirmDialog({
        message: 'Are you sure?',
        closable: false,
        acceptLabel: 'Delete',
        rejectLabel: 'Cancel',
        accept() {
          apiFactory(DriversApi).apiDriversIdDelete({id: rowData.id!})
          .then(() => {
            props.setDriver(rowData, true);
          })
          .catch(reason => {
            showErrorToast('Error: It is impossible to delete the unit because there are other records linked to it.');
            console.error('Error on deleting unit:', reason);
          })
        },
      });
    }}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  />, [props.setDriver]);

  const columns = useMemo(() => {
    return requestDriverListColumnDef.concat({
      field: 'actions',
      header: 'Actions',
      style: { flex: 1 },
      sortable: false,
      body: (rowData: Driver) => removeActionButton(rowData),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeActionButton]);
  
  return <>
    <LogisticsDataGridBase
      columns={columns}
      entities={props.drivers}
      fullSize={false}
      rightHeaderElements={[
        addDriverButton,
      ]}
      onRowClick={e => driverModal.show(e.data)}
    />
    {driverEdit}
  </>
}

export default RequestDriverList;