import { AnyFunction } from '../../../../shared/interfaces/AnyFunction';
import React, { useMemo, useRef } from 'react';
import { NotificationArrowButton } from './NotificationArrowButton';

export const DispStatusChangeNotification = (props: {
  id: number,
  trackingId: number,
  vehicleId?: number,
  onArrowClick: AnyFunction<any, any>
}) => {
  const ref = useRef<HTMLDivElement>({} as any);
  const notificationMessage = useMemo(
    () => `Vehicle ${props.vehicleId} tracking status changed`,
    [props.vehicleId]);

  return <div
    className='flex flex-row flex-grow-1 justify-content-evenly align-items-center text-2xl'
    ref={ref}
  >
    <i className='pi pi-exclamation-triangle text-2xl flex-grow-0 pr-4' />
    <span className='flex-grow-1'>{notificationMessage}</span>
    <NotificationArrowButton
      onClick={() => {
        props.onArrowClick();
        const closeButton = ref.current.parentElement!.querySelector('button.p-toast-icon-close') as HTMLButtonElement;
        closeButton.click();
      }}
      className='flex-grow-0 pl-4'
    >
      <i className='pi pi-angle-right text-2xl' />
    </NotificationArrowButton>
  </div>;
};