import { useEffect, useState, useCallback, useMemo } from 'react';
import {
  ApiBidsIdPutRequest,
  Bid,
  BidsApi,
  BidStatus,
  BidStatusesApi, EDriverOrder,
  ListOfLoads,
  Offer,
  Tracking,
} from '../../../generated-api';
import { ModalParameters } from '../../../shared';
import { PricesData } from '../../../components/prices/PricesBlock';
import { apiFactory } from '../../../shared';
import { formatTime } from '../../../shared';
import './BidEdit.css';
import { useHubConnection } from '../../../hooks/SignalR';
import { useAppDispatch, useAppSelector, useModal } from '../../../hooks';
import { useFormik } from 'formik';
import { showErrorToast, showSuccessToast, showToast } from '../../../components/LogisticsToast';
import { Typography } from 'antd';
import ListOfLoadsEdit from '../../list-of-loads/ListOfLoadsEdit';
import { getSystemSetting } from '../../settings/SettingsSlice';
import LsNumber, { lsNumberToString } from '../../list-of-loads/LsNumber';
import dayjs from 'dayjs';
import TrackingEdit from '../../tracking/TrackingEdit';

const { Link } = Typography;


const createPrices = (data: Bid | undefined) => {
  return {
    priceForBroker: data?.priceForBroker,
    priceForDrive: data?.priceForDrive,
    pricePerMileBr: data?.pricePerMileBr,
    pricePerMileDr: data?.pricePerMileDr,
    miles: data?.loadedOrder?.miles,
    profit: data?.profit,
  };
};

export interface BidEditParameters extends ModalParameters<Bid> {
}

export function useBidEdit(props: BidEditParameters) {
  const [data, setData] = useState(props.data);
  const [pricesData, setPricesData] = useState<PricesData>(createPrices(data));
  const [stateLoading, setLoading] = useState(props.loading);
  const [selectedUnit, setSelectedUnit] = useState<Offer | undefined>({});
  const [bidStatuses, setBidStatuses] = useState<BidStatus[]>([]);
  const lolModal = useModal<ListOfLoads>({});
  const trackingModal = useModal<Tracking>({});
  const hubConnection = useHubConnection();
  const systemSettings = useAppSelector(state => state.settings.system);
  const dispatch = useAppDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const hasReplies = useMemo(() => Boolean(data.bidReplies?.length), [data?.bidReplies]);
  const formik = useFormik<Bid>({
    initialValues: data,
    validate: () => {},
    onSubmit: () => {},
  });

  useEffect(() => {
    if (!props.visible) return;

    setLoading(true);

    const bidPromise = props?.data?.id
      ? apiFactory(BidsApi).apiBidsIdGet({ id: props.data.id })
      : Promise.resolve(data);
    const systemSettingsPromise = systemSettings.mcNumber && systemSettings.abbreviation
      ? Promise.resolve()
      : dispatch(getSystemSetting({ id: 1}));
    Promise.all([
      bidPromise,
      apiFactory(BidsApi).apiBidsSelectedVehicleGet({ bidId: props.data?.id! }),
      apiFactory(BidStatusesApi).apiBidStatusesGet(),
      systemSettingsPromise,
    ]).then(([loadedBid, loadedSelectedVehicle, loadedBidStatuses]) => {
      setData(loadedBid);
      setSelectedUnit({
        loadedOrder: loadedBid.loadedOrder,
        vehicle: loadedSelectedVehicle.vehicle,
        vehicleFlyMiles: loadedSelectedVehicle.flyMiles,
        loadedOrderId: loadedBid.loadedOrderId || loadedBid.loadedOrder?.id,
        vehicleId: loadedSelectedVehicle.vehicleId || loadedSelectedVehicle.vehicle?.id,
      } as Offer);
      setPricesData(createPrices(loadedBid));

      if (loadedBidStatuses && loadedBidStatuses.length) {
        setBidStatuses(loadedBidStatuses)
      }

      console.debug('sending SendReadBid message');
      hubConnection?.send('SendReadBid', loadedBid.id);
    }).catch(reason => {
      showErrorToast('Something went wrong with preloading information on the form');
      console.error('Error on orders loading:', reason);
    })
    .finally(() => {
      setLoading(false);
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.visible]);

  const changePrices = (prices: PricesData) => {
    setPricesData({ ...prices });
    formik.setValues({ ...data, ...prices});
  };

  const hide = () => {
    props.hide(data);
    setLoading(false);
  };

  const handleOnHide = useCallback(() => {
    hide();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onAccept = () => {
    if (!data?.id) return Promise.resolve();

    const acceptedStatus = bidStatuses.find(status => status.name === 'ACCEPTED')

    const bidToSave = {
      ...data,
      profit: pricesData.profit,
      priceForBroker: pricesData.priceForBroker,
      priceForDrive: pricesData.priceForDrive,
      pricePerMileBr: pricesData.pricePerMileBr,
      pricePerMileDr: pricesData.pricePerMileDr,
      status: acceptedStatus,
      statusId: acceptedStatus?.id
    }

    setData({
      ...bidToSave
    });

    const request: ApiBidsIdPutRequest = {
      id: data?.id,
      bid: {
        ...bidToSave
      },
    };

    setLoading(true);
    return apiFactory(BidsApi).apiBidsIdPut(request).then(() => {
      showSuccessToast('Bid was accepted');
    }).catch(reason => {
      showToast({
        severity: 'error',
        summary: 'Error',
        detail: 'Error on bid accepting',
        life: 2000
      });
      console.error('Error on bid accepting:', reason);
    }).finally(() => {
      setLoading(false);
    });
  };

  const listOfLoadsLink = useMemo(() => {
    return data?.listOfLoads?.length ?
      <Link underline className='text-lg ml-4' onClick={() => {
        lolModal.show({});
      }}>
        List of Loads
      </Link>
      : <></>
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [data?.listOfLoads?.length, lolModal.visible]);

  const trackingLink = useMemo(() => {
    return data?.trackings?.length ?
      <Link underline className='text-lg ml-4' onClick={() => {
        trackingModal.show({});
      }}>
        Tracking
      </Link>
      : <></>
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [data?.trackings?.length, trackingModal.visible]);

  const viewOnMapLink = () => {
    const link = `https://www.google.com/maps/dir/?api=1&origin=${encodeURI(data.loadedOrder?.pickUpAt!)}&destination=${encodeURI(data.loadedOrder?.deliverTo!)}`;
    return (
    <a
      className='loaded-order-map-link text-lg pl-4'
      target="_blank"
      rel="noreferrer"
      href={link}>
        View on Map
    </a>)
  };

  const extraLinks = <>
  {listOfLoadsLink} {trackingLink} {viewOnMapLink()}
  </>

  //eslint-disable-next-line @typescript-eslint/no-unused-vars
  const bidReplyLink = useMemo(() => {
    if (data?.bidReplies?.length) {
      const link = `https://mail.google.com/mail/u/1/#inbox/${data.bidReplies[0].messageId}`;
      return (
      <a
        target="_blank"
        rel="noreferrer"
        href={link}>
          {data.bidReplies[0].text}
      </a>)
    } else {
      return <></>;
    }
  },
  [data?.bidReplies]);

  const parseDataFromAdress = (adress: string | null | undefined) : [string, string, number | null] => {
    let city: string = '';
    let state: string = '';
    let index: number | null = null;

    if (!adress) return [ city, state, index ]

    const [ cityFromArray, stateAndIndex ] = adress.split(',')
    city = cityFromArray;
    state = stateAndIndex.substring(0, 3).trim();
    const indexAsNumber = parseInt(stateAndIndex.substring(3).trim())
    index = isNaN(indexAsNumber) ? null : indexAsNumber;
    return [ city, state, index ]
  }

  const listOfLoadsEdit = useMemo(() => {
    const [ pickUpAtCity, pickUpAtState, pickUpAtIndex ] = parseDataFromAdress(data?.loadedOrder?.pickUpAt);
    const [ deliverToCity, deliverToState, deliverToIndex ] = parseDataFromAdress(data?.loadedOrder?.deliverTo);
    let dFirstName: string = '';
    let dLastName: string = '';
    const selectedUnitsDrivers = selectedUnit?.vehicle?.drivers;
    if (selectedUnitsDrivers?.length) {
      const firstDriver =
        selectedUnitsDrivers.filter(d => d.order === EDriverOrder.First).find(x => x) ||
        selectedUnitsDrivers[0];
      dFirstName = firstDriver.firstName || '';
      dLastName = firstDriver.lastName || '';
    }

    const listOfLoadsFromBids: ListOfLoads = {
      number: lsNumberToString({
        abbreviation: systemSettings.abbreviation,
        week: dayjs().tz(),
        consecutiveNumber: 0,
      } as LsNumber),
      syl: data?.loadedOrder?.orderNumber || '0',
      createtime: new Date(),
      vehicleId: selectedUnit?.vehicleId,
      dFirstName,
      dLastName,
      companyName: selectedUnit?.vehicle?.owner?.companyName || undefined,
      pickUpAtCity,
      pickUpAtState,
      pickUpAtIndex,
      pickUpDate: data?.loadedOrder?.pickUpDate || new Date(),
      deliverToCity,
      deliverToState,
      deliverToIndex,
      deliveryDate: data?.loadedOrder?.deliveryDate || new Date(),
      userFullName: `${data?.dispatcher?.firstName || ''} ${data?.dispatcher?.lastName || ''}`,
      user: data.dispatcher,
      miles: data?.loadedOrder?.miles,
      priceForDriver: data?.priceForDrive,
      profit: (data?.priceForBroker || 0) - (data?.priceForDrive || 0),
      profitPercent: data?.profit || 0,
      gross: (data?.priceForBroker || 0),
      note: data?.loadedOrder?.notes,
      bidId: data?.id,
    }

    return lolModal.visible
      ? <ListOfLoadsEdit
        data={data?.listOfLoads?.length ? data.listOfLoads[0] : {...listOfLoadsFromBids}}
        loading={false}
        visible={lolModal.visible}
        hide={listOfLoads => {
          if (listOfLoads) {
            data.listOfLoads = [listOfLoads];
            if (data?.status?.name !== 'ACCEPTED') onAccept();
          }

          lolModal.hide();
        }} />
      : <></>;
    },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [data, lolModal.visible, data.listOfLoads]);

  const trackingEdit = useMemo(() => {
    const [ pickUpAtCity, pickUpAtState ] = parseDataFromAdress(data?.loadedOrder?.pickUpAt);
    const [ deliverToCity, deliverToState ] = parseDataFromAdress(data?.loadedOrder?.deliverTo);
    const selectedUnitsDrivers = selectedUnit?.vehicle?.drivers;
    const selectedDriver = selectedUnitsDrivers && selectedUnitsDrivers?.length > 0
      ? (selectedUnitsDrivers[0] || (selectedUnitsDrivers?.length > 1 ? selectedUnitsDrivers[1] : undefined)) : undefined;

    const trackingFromBids : Tracking = {
      createTime: new Date(),
      pickUpAtCity: pickUpAtCity,
      pickUpAtState: pickUpAtState,
      deliverToCity: deliverToCity,
      deliverToState: deliverToState,
      driver: selectedDriver,
      lastTime: new Date(),
      owner: selectedUnit?.vehicle?.owner,
      bidId: data?.id,
    };

    return trackingModal.visible
    ? <TrackingEdit
      data={data?.trackings?.length ? data.trackings[0] : {...trackingFromBids}}
      loading={false}
      visible={trackingModal.visible}
      hide={tracking => {
        if (tracking) {
          data.trackings = [tracking];
        }

        trackingModal.hide();
      }}/> : <></>
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, trackingModal.visible, data.listOfLoads]);

  return {
    data,
    setData,
    listOfLoadsEdit,
    trackingEdit,
    handleOnHide,
    formatTime,
    selectedUnit,
    setSelectedUnit,
    extraLinks,
    changePrices,
    pricesData,
    lolModal,
    trackingModal,
    stateLoading,
    systemSettings
  }
}

export type BidEditData = ReturnType<typeof useBidEdit>;