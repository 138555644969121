/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LogisticsFile } from './LogisticsFile';
import {
    LogisticsFileFromJSON,
    LogisticsFileFromJSONTyped,
    LogisticsFileToJSON,
} from './LogisticsFile';

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    login?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    lastName?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    activated: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    langKey?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    imageUrl?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    resetDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    refLink?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    createdDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    lastModifiedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    lastModifiedDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    mobileSettings?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    deleted?: boolean | null;
    /**
     * 
     * @type {LogisticsFile}
     * @memberof User
     */
    photo?: LogisticsFile;
    /**
     * 
     * @type {Array<string>}
     * @memberof User
     */
    phones?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof User
     */
    mobileDeviceIds?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    normalizedUserName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    normalizedEmail?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    emailConfirmed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    passwordHash?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    securityStamp?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    concurrencyStamp?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    phoneNumberConfirmed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    twoFactorEnabled?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    lockoutEnd?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    lockoutEnabled?: boolean;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    accessFailedCount?: number;
}

/**
 * Check if a given object implements the User interface.
 */
export function instanceOfUser(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "activated" in value;

    return isInstance;
}

export function UserFromJSON(json: any): User {
    return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'login': !exists(json, 'login') ? undefined : json['login'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'activated': json['activated'],
        'langKey': !exists(json, 'langKey') ? undefined : json['langKey'],
        'imageUrl': !exists(json, 'imageUrl') ? undefined : json['imageUrl'],
        'resetDate': !exists(json, 'resetDate') ? undefined : (json['resetDate'] === null ? null : new Date(json['resetDate'])),
        'refLink': !exists(json, 'refLink') ? undefined : json['refLink'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (json['createdDate'] === null ? null : new Date(json['createdDate'])),
        'lastModifiedBy': !exists(json, 'lastModifiedBy') ? undefined : json['lastModifiedBy'],
        'lastModifiedDate': !exists(json, 'lastModifiedDate') ? undefined : (json['lastModifiedDate'] === null ? null : new Date(json['lastModifiedDate'])),
        'mobileSettings': !exists(json, 'mobileSettings') ? undefined : json['mobileSettings'],
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
        'photo': !exists(json, 'photo') ? undefined : LogisticsFileFromJSON(json['photo']),
        'phones': !exists(json, 'phones') ? undefined : json['phones'],
        'mobileDeviceIds': !exists(json, 'mobileDeviceIds') ? undefined : json['mobileDeviceIds'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'userName': !exists(json, 'userName') ? undefined : json['userName'],
        'normalizedUserName': !exists(json, 'normalizedUserName') ? undefined : json['normalizedUserName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'normalizedEmail': !exists(json, 'normalizedEmail') ? undefined : json['normalizedEmail'],
        'emailConfirmed': !exists(json, 'emailConfirmed') ? undefined : json['emailConfirmed'],
        'passwordHash': !exists(json, 'passwordHash') ? undefined : json['passwordHash'],
        'securityStamp': !exists(json, 'securityStamp') ? undefined : json['securityStamp'],
        'concurrencyStamp': !exists(json, 'concurrencyStamp') ? undefined : json['concurrencyStamp'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'phoneNumberConfirmed': !exists(json, 'phoneNumberConfirmed') ? undefined : json['phoneNumberConfirmed'],
        'twoFactorEnabled': !exists(json, 'twoFactorEnabled') ? undefined : json['twoFactorEnabled'],
        'lockoutEnd': !exists(json, 'lockoutEnd') ? undefined : (json['lockoutEnd'] === null ? null : new Date(json['lockoutEnd'])),
        'lockoutEnabled': !exists(json, 'lockoutEnabled') ? undefined : json['lockoutEnabled'],
        'accessFailedCount': !exists(json, 'accessFailedCount') ? undefined : json['accessFailedCount'],
    };
}

export function UserToJSON(value?: User | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'login': value.login,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'activated': value.activated,
        'langKey': value.langKey,
        'imageUrl': value.imageUrl,
        'resetDate': value.resetDate === undefined ? undefined : (value.resetDate === null ? null : value.resetDate.toISOString()),
        'refLink': value.refLink,
        'createdBy': value.createdBy,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate === null ? null : value.createdDate.toISOString()),
        'lastModifiedBy': value.lastModifiedBy,
        'lastModifiedDate': value.lastModifiedDate === undefined ? undefined : (value.lastModifiedDate === null ? null : value.lastModifiedDate.toISOString()),
        'mobileSettings': value.mobileSettings,
        'deleted': value.deleted,
        'photo': LogisticsFileToJSON(value.photo),
        'phones': value.phones,
        'mobileDeviceIds': value.mobileDeviceIds,
        'id': value.id,
        'userName': value.userName,
        'normalizedUserName': value.normalizedUserName,
        'email': value.email,
        'normalizedEmail': value.normalizedEmail,
        'emailConfirmed': value.emailConfirmed,
        'passwordHash': value.passwordHash,
        'securityStamp': value.securityStamp,
        'concurrencyStamp': value.concurrencyStamp,
        'phoneNumber': value.phoneNumber,
        'phoneNumberConfirmed': value.phoneNumberConfirmed,
        'twoFactorEnabled': value.twoFactorEnabled,
        'lockoutEnd': value.lockoutEnd === undefined ? undefined : (value.lockoutEnd === null ? null : value.lockoutEnd.toISOString()),
        'lockoutEnabled': value.lockoutEnabled,
        'accessFailedCount': value.accessFailedCount,
    };
}

