import { ModalParameters } from '../../shared';
import {
  Driver,
  DriversApi, EDriverOrder,
  EEquipment,
  EVehicleStatus,
  Owner,
  OwnersApi,
  State,
  StatesApi,
  User,
  Vehicle,
  VehiclesApi,
  VehicleType,
  VehicleTypesApi,
} from '../../generated-api';
import React, { SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import LogisticsDialog from '../../components/LogisticsDialog';
import EditableDropdown from '../../components/form/EditableDropdown';
import dayjs from 'dayjs';
import { apiFactory } from '../../shared';
import StyledInputNumber from '../../components/form/StyledInputNumber';
import StyledInputText from '../../components/form/StyledInputText';
import VehicleStatus from '../../data-moqs/VehicleStatus';
import Field from '../../components/form/Field';
import LogisticsCalendar from '../../components/form/datetime/LogisticsCalendar';
import { Button } from 'primereact/button';
import { updateEntity } from './VehiclesSlice';
import { ModalWidget, useAppDispatch, useModal } from '../../hooks';
import DriverEdit from '../drivers/DriverEdit';
import OwnerEdit from '../owners/OwnerEdit';
import { FormikErrors, useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import ErrorBox from '../../components/form/ErrorBox';
import CenteredSpinner from '../../components/CenteredSpinner';
import Row from '../../components/form/Row';
import Column from '../../components/form/Column';
import { logisticsConfirmDialog } from '../../components/LogisticsConfifmDialog';
import useRights from '../../hooks/RightsHook';
import { showErrorToast, showSuccessToast, showToast } from '../../components/LogisticsToast';
import VisibilityToggler from '../../components/VisibilityToggler';
import { IdName } from '../../data-moqs/IdName';
import LogisticsDropdownItem from '../../components/form/LogisticsDropdownItem';
import LogisticsDateOnly from '../../components/form/datetime/LogisticsDateOnly';
import { MultiSelect } from 'primereact/multiselect';
import Equipment from '../../data-moqs/Equipment';

export interface VehicleEditParameters extends ModalParameters<Vehicle> {

}

const statusSystemToPrintable = [
  {
    id: EVehicleStatus.Error,
    class: 'bg-red-400'
  },
  {
    id: EVehicleStatus.Retried,
    class: ''
  },
  {
    id: EVehicleStatus.OnLoad,
    class: '',
  },
  {
    id: EVehicleStatus.UnconfinedService,
    class: 'bg-yellow-400',
  },
  {
    id: EVehicleStatus.InService,
    class: 'bg-green-300',
  },
  {
    id: EVehicleStatus.OutOfService,
    class: 'bg-red-400'},
];

type DriverOrder = 'First' | 'Second';

const driverFullName = (driver: Driver | undefined) =>
  driver ? `${driver.firstName} ${driver.lastName} #${driver.id}` : '';

const ownerFullName = (owner: Owner | undefined) =>
  owner ? `${owner?.firstName} ${owner?.lastName} (${owner?.companyName}) #${owner.id}` : 'NO OWNER';

const getDriver = (vehicle: Vehicle, order: DriverOrder) => {
  return vehicle.drivers ? vehicle.drivers.find(d => d.order === order) : undefined;
}

const replaceDriver = (vehicle: Vehicle, newDriver: Driver, order: DriverOrder) => {
  let drivers = vehicle.drivers ? [...vehicle.drivers] : [];
  let previousDriver = drivers.find(d => d.order === order);

  // Release previous driver
  if (previousDriver) {
    drivers = drivers.filter(d => d !== previousDriver);
    if (previousDriver.id === newDriver?.id) {
      // Do nothing, same ids means driver was edited.
    } else if (previousDriver.vehicleId === vehicle.id) {
      // Detach driver from vehicle
      previousDriver = { ...previousDriver, order: EDriverOrder.None, vehicle: undefined, vehicleId: undefined};
    } else {
      // Driver was never attached to vehicle so forget them.
    }
  }

  if (newDriver) {
    newDriver.order = order;
    // Reassign to another position
    let alreadyAssignedDriver = drivers.find(d =>
      d.id === newDriver.id
      && d.order !== newDriver.order
      && d.order !== EDriverOrder.None);
    if (alreadyAssignedDriver) {
      alreadyAssignedDriver.order = order;
    } else {
      drivers.push(newDriver);
    }
  }



  return { ...vehicle, drivers: drivers };
}

const dimensionsToArray = (value: string) => {
  if (!value.match(/\d+x\d+x\d+/)) return [];

  return value.split('x').map(num => parseInt(num));
}

const dimensionsToString = (value: number[] | null | undefined) => {
  if (!value || value.length < 3) return '';

  return `${value[0]}x${value[1]}x${value[2]}`;
}

const startYear = 2000;
const endYear = new Date().getFullYear();
const years : number[] = [];
for (var i = startYear; i <= endYear; i++) {
  years.push(i);
}

const equipmentToEquipment = (equipment: EEquipment[] | undefined | null) =>
  equipment?.length ? Equipment.filter(idstring => equipment.some(e => e.toString() === idstring.id)) : undefined;

const statusToStatus = (status: EVehicleStatus | undefined | null) =>
  status ? VehicleStatus.find(x => x.id === status.toString()) : undefined;

const vinIsNotUniqueMessage = 'This VIN code is already in the system.';

function VehicleEdit(props: VehicleEditParameters) {
  const { visible } = props;
  const [vehicle, setVehicle] = useState<Vehicle>(props.data);
  const [drivers, setDrivers] = useState<Driver[]>([]);
  const [owners, setOwners] = useState<Owner[]>([]);
  const [vehicleTypes, setVehicleTypes] = useState<VehicleType[]>([]);
  const [states, setStates] = useState<State[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [vinIsNotUnique, setVinIsNotUnique] = useState<boolean>(false);
  const [boxDimensions, setBoxDimensions] = useState(dimensionsToString(vehicle.boxDimensions));
  const driver1Modal = useModal<Driver>({});
  const driver2Modal = useModal<Driver>({});
  const ownerModal = useModal<Owner>({});
  const [loading, setLoading] = useState(false);
  const rights = useRights(security => security.vehicle);
  const canUpdate = useMemo(() => !!rights.update, [rights]);
  const canUpdateOperative = useMemo(() => !!rights.editOperativeFields, [rights]);
  const dispatch = useAppDispatch();
  const validation = useFormik<Vehicle>({
    initialValues: vehicle,
    validate: data => {
      const errors : FormikErrors<Vehicle> = {};
      const blankError = 'This value should not be blank.';

      const requiredFields: Vehicle = {
        payload: undefined,
        boxDimensions: undefined,
        status: undefined,
        availableDate: undefined,
        vehicleMake: undefined,
        vehicleModel: undefined,
        vehicleYear: undefined,
        vin: undefined,
        licensePlate: undefined,
        licenceState: undefined,
        licenseExpiryDate: undefined,
        insuranceExpires: undefined,
      };

      if (data.status === EVehicleStatus.Retried && !data.note) {
        errors.note = blankError;
      }

      Object.keys(requiredFields).forEach(field => {
        if (!(data as any)[field])
          (errors as any)[field] = blankError;
      });

      if (vinIsNotUnique) {
        errors.vin = vinIsNotUniqueMessage;
      }

      return errors;
    },
    onSubmit: () => {},
  });

  const createDate = vehicle.createdDate
    ? dayjs.tz(vehicle.createdDate).format('MMM DD YYYY')
    : '';

  useEffect(() => {
    if (!visible) return;

    setLoading(true);
    const vehiclePromise = props.data.id
      ? apiFactory(VehiclesApi).apiVehiclesIdGet({ id: props.data.id})
      : Promise.resolve(vehicle);

    Promise.all([
      vehiclePromise,
      apiFactory(DriversApi).apiDriversVehicleDropdownGet(),
      apiFactory(OwnersApi).apiOwnersFullNameCompanyGet(),
      apiFactory(VehicleTypesApi).apiVehicleTypesPermittedGet(),
      apiFactory(StatesApi).apiStatesCodesGet(),
      apiFactory(VehiclesApi).apiVehiclesAllUsersGet()
    ]).then(([
        loadedVehicle,
        loadedDrivers,
        loadedOwners,
        vehicleTypes,
        loadedStates,
        users,
    ]) => {
      if (loadedVehicle.vin) {
        apiFactory(VehiclesApi).apiVehiclesCheckVinUniquenessVinGet({vin: loadedVehicle.vin, vehicleId: loadedVehicle.id!})
          .then(isUniqueString => {
            const isUnique = isUniqueString.toString() === 'true';
            setVinIsNotUnique(!isUnique);
          });
      }
      setVehicle(loadedVehicle);
      setDrivers(loadedDrivers);
      setOwners(loadedOwners);
      setVehicleTypes(vehicleTypes);
      setStates(loadedStates);
      setUsers(users as User[]);
      validation.resetForm({ values: loadedVehicle, touched: {}});
    }).catch(() => showErrorToast('Something went wrong'))
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const setValue = useCallback((setState: SetStateAction<Vehicle>) => {
    validation.setValues(setState);
    setVehicle(setState);
  }, [validation]);

  const hide = useCallback((savedVehicle? : Vehicle) => {
    if (savedVehicle) {
      dispatch(updateEntity(savedVehicle));
    }

    props.hide(savedVehicle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.hide]);

  const validate = useCallback(async () => {
    validation.handleSubmit();
    const errors = await validation.validateForm(vehicle);
    if (Object.keys(errors).length) {
      throw new Error('Fix errors and try again');
    }
  }, [vehicle, validation]);

  const save = useCallback(async () => {
    try {
      await validate();
      setLoading(true)
      vehicle.drivers = vehicle.drivers?.filter(x => x);

      const response = vehicle.id
        ? await apiFactory(VehiclesApi)
          .apiVehiclesIdPut({ id: vehicle.id!, vehicle: vehicle })
        : await apiFactory(VehiclesApi)
          .apiVehiclesPost({ vehicle: vehicle });
      showSuccessToast('Vehicle saved');
      if (response) {
        setVehicle(response);
      }

      validation.resetForm({ values: response || vehicle, touched: {} });
      hide(response || vehicle);
    } catch (e: any) {
      showToast({severity: 'error', summary: 'Error', detail: e.toString()});
    } finally {
      setLoading(false);
    }
  }, [vehicle, validate, validation, hide]);

  const handleOnHide = useCallback(() => {
    if (!rights.update && !rights.create && !rights.editOperativeFields) {
      hide();
      return;
    }

    if (validation.dirty) {
      logisticsConfirmDialog({
        message: 'Save changes?',
        closable: false,
        accept() {
          save().then(() => hide())
        },
        reject() {
          hide();
        }
      });
    } else {
      hide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.hide, vehicle, validation, rights]);

  const driverEdit = (modal: ModalWidget<Driver>, order: DriverOrder) => modal.visible
    ? <DriverEdit
      data={modal.data}
      loading={false}
      visible={modal.visible}
      openedFromRequest={false}
      hide={editedDriver => {
        if (!editedDriver) {
          modal.hide();
          return;
        }

        setVehicle(v =>
          replaceDriver(v, editedDriver, order));
        const driverIsNew = !drivers.find(x => x.id === editedDriver.id);
        setDrivers(oldDrivers => {
          return driverIsNew
            ? [editedDriver, ...oldDrivers]
            : [...oldDrivers].map(oldDriver => {
                return oldDriver.id === modal.data.id
                  ? editedDriver
                  : oldDriver;
              });
        });
        modal.hide();
      }}
    />
    : <></>;

  const ownerEdit = ownerModal.visible
    ? <OwnerEdit
      data={ownerModal.data}
      loading={false}
      visible={ownerModal.visible}
      hide={editedOwner => {
        if (!editedOwner) {
          ownerModal.hide();
          return;
        }

        setVehicle(v => {
          return { ...v, owner: editedOwner };
        });
        const ownerIsNew = !owners.find(x => x.id === editedOwner.id);
        setOwners(oldOwners => {
          return ownerIsNew
            ? [editedOwner, ...oldOwners]
            : [...oldOwners].map(oldOwner => {
              return oldOwner.id === ownerModal.data.id
                ? editedOwner
                : oldOwner;
            });
        });
        ownerModal.hide();
      }}
    />
    : <></>;

  const driver1Edit = driverEdit(driver1Modal, 'First');
  const driver2Edit = driverEdit(driver2Modal, 'Second');

  const statusTemplate = (option? : IdName<string>) => {
    const style = statusSystemToPrintable.find(x => x.id === option?.id)?.class;
    return <LogisticsDropdownItem className={style}>{option?.name}</LogisticsDropdownItem>;
  };

  const onVehicleBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    const fieldValue = e.currentTarget.value;
    const zipcodeRegex = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
    if (zipcodeRegex.test(fieldValue)) {
      apiFactory(VehiclesApi).apiVehiclesAddressZipcodeGet({ zipcode: vehicle.availableCity || ''})
      .then(address => {
        if (address) {
          setValue(vehicle => { return {...vehicle, availableCity: address} });
        } else {
          showToast({
            severity: 'error',
            summary: 'Error',
            detail: 'The address with the specified zip code was not found',
            life: 4000
          });
        }
      })
      .catch(error => {
        console.error(error);
        showToast({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to find an address with the specified zip code',
          life: 4000
        });
      });
    }
  }

  const onVinBlur = async (e: React.FocusEvent<HTMLInputElement, Element>) => {
    const fieldValue = e.currentTarget.value;
    if (fieldValue) {
      apiFactory(VehiclesApi).apiVehiclesCheckVinUniquenessVinGet({vin: fieldValue, vehicleId: vehicle.id})
        .then(isUniqueString => {
          const isUnique = isUniqueString.toString() === 'true';
          setVinIsNotUnique(!isUnique);
        })
        .catch((error: any) => {
          showErrorToast("Failed to check the uniqueness of the Vin field.")
          setVinIsNotUnique(true);
          throw error;
        })
        .finally(async () => {
          setTimeout(() => validation.setFieldTouched('vin', true));
        });
    }
  }

  return (
    <>
      {driver1Edit}
      {driver2Edit}
      {ownerEdit}
      <LogisticsDialog
        visible={props.visible}
        onHide={handleOnHide}
        className='logistics-dialog-edit-form logistics-dialog-edit-form_loadable w-6'
      >
        <header className='text-2xl font-bold'>
          Vehicle #V{vehicle.id}
        </header>
        <div className='text-lg mt-2 mb-5'>
          Added by {vehicle.createdBy} {createDate}
        </div>
        <main className='grid logistics-dialog-edit-form__content'>
          <Row>
            <Column>
              <Field label='Driver #1'>
                <EditableDropdown
                  options={drivers}
                  optionLabel='firstName'
                  renderOption={driverFullName}
                  renderSelected={driverFullName}
                  renderEmptyOption={() => 'No driver'}
                  renderEmptySelected={() => 'No driver'}
                  value={getDriver(vehicle, 'First')}
                  disabled={!canUpdate}
                  openEditing={value => driver1Modal.show(value)}
                  openAdding={() => driver1Modal.show({})}
                  onChange={newValue => setValue(v =>
                    replaceDriver(v, newValue, 'First')
                  )}
                  filter
                />
                <ErrorBox>{validation.errors.drivers}</ErrorBox>
              </Field>
            </Column>
            <Column>
              <Field label='Vehicle make' required>
                <StyledInputText
                  // @ts-ignore
                  value={vehicle.vehicleMake}
                  disabled={!canUpdate}
                  onChange={e => setValue(v => {
                    return { ...v, vehicleMake: e.target.value };
                  })}
                />
                <ErrorBox>{validation.errors.vehicleMake}</ErrorBox>
              </Field>
            </Column>
          </Row>
          <Row>
            <Column>
              <Field label='Driver #2'>
                <EditableDropdown
                  options={drivers}
                  optionLabel='firstName'
                  renderOption={driverFullName}
                  renderSelected={driverFullName}
                  renderEmptyOption={() => 'No driver'}
                  renderEmptySelected={() => 'No driver'}
                  disabled={!canUpdate}
                  openEditing={value => driver2Modal.show(value)}
                  openAdding={() => driver2Modal.show({})}
                  value={getDriver(vehicle, 'Second')}
                  onChange={newValue => setValue(v =>
                    replaceDriver(v, newValue, 'Second')
                  )}
                  filter
                />
              </Field>
            </Column>
            <Column>
              <Field label='Vehicle model' required>
                <StyledInputText
                  // @ts-ignore
                  value={vehicle.vehicleModel}
                  disabled={!canUpdate}
                  onChange={e => setValue(v => {
                    return { ...v, vehicleModel: e.target.value };
                  })}
                />
                <ErrorBox>{validation.errors.vehicleModel}</ErrorBox>
              </Field>
            </Column>
          </Row>
          <Row>
            <div className='col-10'>
              <Field label='Owner'>
                <EditableDropdown
                  options={owners}
                  optionLabel='firstName'
                  renderOption={ownerFullName}
                  renderSelected={ownerFullName}
                  value={vehicle.owner}
                  disabled={!canUpdate}
                  openEditing={value => ownerModal.show(value)}
                  openAdding={() => ownerModal.show({})}
                  onChange={newValue => {
                    setValue(v => { return {...v, owner: newValue}});
                  }}
                  others={{
                    className: classNames({ 'p-invalid': validation.errors.owner})
                  }}
                  filter
                />
                <ErrorBox>{validation.errors.owner}</ErrorBox>
              </Field>
            </div>
            <div className='col-2'>
              <Field label='Vehicle year' required>
                <EditableDropdown
                  options={years}
                  optionLabel=''
                  renderOption={x => x}
                  renderSelected={x => x}
                  renderEmptySelected={() => '-'}
                  value={vehicle.vehicleYear}
                  disabled={!canUpdate}
                  onChange={newValue => setValue(v => {
                    return { ...v, vehicleYear: newValue };
                  })}
                  filter
                />
                <ErrorBox>{validation.errors.vehicleYear}</ErrorBox>
              </Field>
              </div>
          </Row>
          <Row>
            <Column>
              <Field label='Vehicle type'>
                <EditableDropdown
                  options={vehicleTypes}
                  optionLabel='name'
                  renderOption={type => type.name}
                  renderSelected={type => type?.name}
                  renderEmptySelected={() => '-'}
                  value={vehicle.vehicleType}
                  disabled={!canUpdate}
                  onChange={newValue => {
                    setValue(v => { return { ...v, vehicleType: newValue }});
                  }}
                  filter
                />
                <ErrorBox>{validation.errors.vehicleType}</ErrorBox>
              </Field>
            </Column>
            <Column>
              <Field label='VIN' required>
                <StyledInputText
                  // @ts-ignore
                  value={vehicle.vin}
                  disabled={!canUpdate}
                  onBlur={onVinBlur}
                  onChange={e => setValue(v => {
                    return { ...v, vin: e.target.value };
                  })}
                />
                <ErrorBox>{validation.errors.vin}</ErrorBox>
              </Field>
            </Column>
          </Row>
          <Row>
            <Column>
              <Field label='Payload (lbs)' required>
                <StyledInputNumber
                  value={vehicle.payload}
                  disabled={!canUpdateOperative && !canUpdate}
                  onChange={e => setValue(v => {
                    return { ...v, payload: e.value || 0 };
                  })}
                />
                <ErrorBox>{validation.errors.payload}</ErrorBox>
              </Field>
            </Column>
            <Column>
              <Field label='License Plate' required>
                <StyledInputText
                  // @ts-ignore
                  value={vehicle.licensePlate}
                  disabled={!canUpdate}
                  onChange={e => setValue(v => {
                    return { ...v, licensePlate: e.target.value };
                  })}
                />
                <ErrorBox>{validation.errors.licensePlate}</ErrorBox>
              </Field>
            </Column>
          </Row>
          <Row>
            <Column>
              <Field label='Box Dims (LxWxH)' required>
                <StyledInputText
                  value={boxDimensions}
                  placeholder='_x_x_'
                  disabled={!canUpdateOperative && !canUpdate}
                  onChange={e => {
                    setValue(v => {
                      return { ...v, boxDimensions: dimensionsToArray(e.target.value) };
                    });
                    setBoxDimensions(e.target.value);
                  }}
                />
                <ErrorBox>{validation.errors.boxDimensions}</ErrorBox>
              </Field>
            </Column>
            <Column>
              <Field label='Licence state' required>
                <EditableDropdown
                  options={states}
                  optionLabel='name'
                  renderOption={state => state.code}
                  renderSelected={state => state?.code}
                  renderEmptySelected={() => '-'}
                  value={vehicle.licenceState}
                  disabled={!canUpdate}
                  onChange={newValue => setValue(v => {
                    return { ...v, licenceState: newValue };
                  })}
                  filter
                />
                <ErrorBox>{validation.errors.licenceState}</ErrorBox>
              </Field>
            </Column>
          </Row>
          <Row>
            <Column className='col-12'>
              <Field label='Equipment'>
                <MultiSelect
                  value={equipmentToEquipment(vehicle.equipment)}
                  disabled={!rights.update}
                  onChange={e => setValue(v => {
                    return { ...v, equipment: ((e.value as IdName<string>[]).map(x => x.id)) as EEquipment[] };
                  })}
                  options={Equipment}
                  optionLabel='id'
                  itemTemplate={option => option.name}
                  placeholder='Equipment'
                  maxSelectedLabels={20}
                  className='w-full'
                  filter
                />
              </Field>
              <ErrorBox>{validation.errors.equipment}</ErrorBox>
            </Column>
          </Row>
          <Row>
            <Column>
              <Field label='Status' required>
                <EditableDropdown
                  options={VehicleStatus}
                  optionLabel='id'
                  renderOption={statusTemplate}
                  renderSelected={statusTemplate}
                  renderEmptySelected={() => '-'}
                  value={statusToStatus(vehicle.status)}
                  disabled={!canUpdateOperative && !canUpdate}
                  onChange={newStatus => setValue(v => {
                    return { ...v, status: newStatus.id as any };
                  })}
                  filter
                />
                <ErrorBox>{validation.errors.status}</ErrorBox>
              </Field>
            </Column>
            <Column>
              <Field label='License Expiry Date' required>
                <LogisticsDateOnly
                  value={vehicle.licenseExpiryDate}
                  disabled={!canUpdate}
                  onChange={newValue => setValue(v => {
                    return { ...v, licenseExpiryDate: newValue };
                  })}
                />
                <ErrorBox>{validation.errors.licenseExpiryDate}</ErrorBox>
              </Field>
            </Column>
          </Row>
          <Row>
            <Column>
              <Field label='Available city'>
                <StyledInputText
                  // @ts-ignore
                  value={vehicle.availableCity}
                  disabled={!canUpdateOperative && !canUpdate}
                  onBlur={onVehicleBlur}
                  onChange={e => {
                    setValue(v => {
                      return { ...v, availableCity: e.target.value };
                    })
                  }}
                />
                <ErrorBox>{validation.errors.availableCity}</ErrorBox>
              </Field>
            </Column>
            <Column>
              <Field label='Insurance Expires' required>
                <LogisticsDateOnly
                  value={vehicle.insuranceExpires}
                  disabled={!canUpdate}
                  onChange={newValue => setValue(v => {
                    return { ...v, insuranceExpires: newValue };
                  })}
                />
                <ErrorBox>{validation.errors.insuranceExpires}</ErrorBox>
              </Field>
            </Column>
          </Row>
          <Row>
            <Column>
              <Field label='Available date' required>
                <LogisticsCalendar
                  value={vehicle.availableDate}
                  showTime
                  showSetToNowButton
                  disabled={!canUpdateOperative && !canUpdate}
                  onChange={newValue => {
                    setValue(v => {
                      return { ...v, availableDate: newValue };
                    })
                  }}
                />
                <ErrorBox>{validation.errors.availableDate}</ErrorBox>
              </Field>
            </Column>
            <Column>
              <Field label='Availability Note' required={vehicle.status === EVehicleStatus.Retried}>
                <StyledInputText
                  // @ts-ignore
                  value={vehicle.note}
                  disabled={!canUpdateOperative && !canUpdate}
                  onChange={e => setValue(v => {
                    return { ...v, note: e.target.value };
                  })}
                />
                <ErrorBox>{validation.errors.note}</ErrorBox>
              </Field>
            </Column>
          </Row>
          <Row>
            <Column>
              <Field label='Previous available city'>
                <StyledInputText
                  // @ts-ignore
                  value={vehicle.previousCity}
                  disabled={true}
                />
              </Field>
            </Column>
            <Column>
              <Field label='Dispatcher'>
              <EditableDropdown
                options={users}
                optionLabel='userName'
                renderOption={user => `${user.firstName} ${user.lastName}`}
                renderSelected={user => `${user?.firstName} ${user?.lastName}`}
                renderEmptySelected={() => '-'}
                value={vehicle.dispatcher}
                onChange={e => setValue(v => {
                  return { ...v, dispatcher: e };
                })}
                disabled={!canUpdateOperative && !canUpdate}
                filter
                />
              {validation.errors.dispatcher}
              </Field>
            </Column>

          </Row>
        </main>
        <footer className='flex justify-content-center w-full gap-3'>
          <VisibilityToggler visible={rights.update || rights.create || rights.editOperativeFields}>
            <Button
              label='SAVE'
              className='p-button-success'
              onClick={save}
            />
          </VisibilityToggler>
          <Button
            label='CLOSE'
            onClick={handleOnHide}
          />
        </footer>
        <CenteredSpinner visible={loading} />
      </LogisticsDialog>
    </>
  );
}

export default VehicleEdit;