import React, { ReactNode, useRef, useState } from 'react';
import { EBusinessType, Owner, RegistrationApi, User } from '../../../generated-api';
import { Card } from 'primereact/card';
import Row from '../../../components/form/Row';
import Column from '../../../components/form/Column';
import Field from '../../../components/form/Field';
import LogisticsCalendar from '../../../components/form/datetime/LogisticsCalendar';
import dayjs from 'dayjs';
import emptyArrowFunction from '../../../shared/util/emptyArrowFunction';
import useRequestEditData, { RequestEditPublicWidget } from './hook';
import EditableDropdown from '../../../components/form/EditableDropdown';
import StyledInputText from '../../../components/form/StyledInputText';
import Header from '../../../components/form/Header';
import StyledInputMask from '../../../components/form/StyledInputMask';
import LogisticsPhoneInput from '../../../components/LogisticsPhoneInput';
import { Checkbox } from 'primereact/checkbox';
import VisibilityToggler from '../../../components/VisibilityToggler';
import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';
import { Button } from 'primereact/button';
import CenteredSpinner from '../../../components/CenteredSpinner';
import ErrorBox from '../../../components/form/ErrorBox';
import businessTypes from '../../owners/businessTypes';
import { Panel } from 'primereact/panel';
import LogisticsFilesUpload from '../../../components/form/files/upload/LogisticsFilesUpload';
import RequestEditCaptions from '../request-edit/RequestEditCaptions';
import RequestDriverList from '../request-edit/drivers/RequestDriverList';
import RequestUnitList from '../request-edit/units/RequestUnitList';
import LogisticsCanvas from '../../../components/canvas/LogisticsCanvas';
import { Button as AntButton } from 'antd';
import { ReactSketchCanvasRef } from 'react-sketch-canvas';
import { apiFactory } from '../../../shared';
import { showSuccessToast } from '../../../components/LogisticsToast';
import LogisticsPdf from '../../../components/form/files/pdf/LogisticsPdf';
import useFilePreview, {
  FilePreviewWidget,
} from '../../../components/form/files/preview/filePreviewWidget';
import LogisticsDateOnly from '../../../components/form/datetime/LogisticsDateOnly';
import { BrowserView, MobileView } from 'react-device-detect';
import OwnerDocumentsTable from '../../owner-document/components/OwnerDocumentsTable';
import LogisticsFilePreview from '../../../components/form/files/preview/LogisticsFilePreview';
import LLCType from '../../../data-moqs/LLCType';

function userFullName(user: User | null | undefined) {
  if (!user) return '';

  return `${user.firstName} ${user.lastName}`;
}

const getPhone = (owner: Owner) =>
  owner.phones?.length ? owner.phones[0] : undefined;

const setPhone = (owner: Owner, phone: string) => {
  const phones = owner.phones?.length ? [...owner.phones] : [];
  phones[0] = phone;
  return phones;
}

const BrowserRequestCard = styled(Card)`
  & .p-card-body, & .p-card-content {
    height: 100vh;
  }

  & .p-card-body {
    overflow-y: auto;
  }
  
  &.p-card {
    height: 90%;
  }
`

function UniversalRequestCard(props: { children?: ReactNode[], header?: ReactNode}) {
  return <>
    <MobileView>
      <Panel header={props.header} toggleable className='mb-1'>
        {props.children}
      </Panel>
    </MobileView>
    <BrowserView>
      <BrowserRequestCard className='mb-1'>
        {props.children}
      </BrowserRequestCard>
    </BrowserView>
  </>
}

function UniversalCard(props: { children?: ReactNode[], header?: ReactNode}) {
  return <>
    <MobileView>
      <Panel header={props.header} toggleable className='mb-1'>
        {props.children}
      </Panel>
    </MobileView>
    <BrowserView>
      <Card className='mb-1'>
        {props.children}
      </Card>
    </BrowserView>
  </>
}

const MobilePanel = styled(Panel)`
  & .p-panel-content {
    padding: 0 !important;
  }
`

function RequestEditPublic() {
  const { id } = useParams();
  const form = useRequestEditData(id ? parseInt(id) : undefined);

  return <>
    <BrowserView>
      <Row style={{height: '100vh'}}>
        <Column className='col-6 h-full my-3'>
          <RequestBlock form={form} />
        </Column>
        <Column className='col-6 my-3 overflow-y-auto' style={{height: '90%'}}>
          <UnitsBlock form={form} />
          <DriversBlock form={form} />
          <SignatureBlock form={form} />
        </Column>
      </Row>
    </BrowserView>
    <MobileView>
      <Row className='overflow-y-auto overflow-x-hidden mx-0' style={{height: '90vh'}}>
        <Column className='col-12'>
          <RequestBlock form={form} />
          <UnitsBlock form={form} />
          <DriversBlock form={form} />
          <SignatureBlock form={form} />
        </Column>
      </Row>
    </MobileView>
  </>
}

function RequestBlock(props: { form: RequestEditPublicWidget}) {
  const { form} = props;
  const [isEditing, setIsEditing] = useState(false);
  const requestHeader = `Request ${form.request.id ? '#' + form.request.id : ''}`;
  const filePreviewWidget = useFilePreview();

  return <UniversalRequestCard header={requestHeader}>
    <BrowserView>
      <div className='grid'>
        <div className='col-6'>
          <Header>{requestHeader}</Header>
        </div>
        <div className='col-6 flex flex-row-reverse align-items-start'>
          <Button
            label='Edit'
            className='p-button-success'
            onClick={() => setIsEditing(!isEditing)}
          />
        </div>
      </div>
    </BrowserView>
    <BrowserView>
      <Row>
        <Column className='col-4'>
          <RingDate form={form} disabled={true} />
        </Column>
        <Column className='col-4'>
          <NextRingDate form={form} disabled={true} />
        </Column>
        <Column className='col-4'>
          <Dispatcher form={form} disabled={true} />
        </Column>
      </Row>
      <Row>
        <Column className='col-12'>
          <Comment form={form} disabled={true} />
        </Column>
      </Row>
    </BrowserView>
    <MobileView>
      <Row>
        <Column className='col-6'>
          <RingDate form={form} disabled={true} />
        </Column>
        <Column className='col-6'>
          <NextRingDate form={form} disabled={true} />
        </Column>
      </Row>
      <Row>
        <Column className='col-6'>
          <Dispatcher form={form} disabled={true} />
        </Column>
        <Column className='col-6'>
          <Comment form={form} disabled={true} />
        </Column>
      </Row>
    </MobileView>
    <Header>Company Info</Header>
    <BrowserView>
      <Row>
        <Column>
          <CompanyName form={form} disabled={!isEditing} />
        </Column>
        <Column>
          <DBA form={form} disabled={!isEditing} />
        </Column>
      </Row>
      <Row>
        <Column>
          <FirstName form={form} disabled={!isEditing} />
        </Column>
        <Column>
          <LastName form={form} disabled={!isEditing} />
        </Column>
      </Row>
      <Row>
        <Column className='col-4'>
          <Email form={form} disabled={!isEditing} />
        </Column>
        <Column className='col-4'>
          <Phones form={form} disabled={!isEditing} />
        </Column>
        <Column className='col-4'>
          <EmployerId form={form} disabled={!isEditing} />
        </Column>
      </Row>
      <Row>
        <Column className='col-4'>
          <Address form={form} disabled={!isEditing} />
        </Column>
        <Column className='col-4'>
          <City form={form} disabled={!isEditing} />
        </Column>
        <Column className='col-4'>
          <State form={form} disabled={!isEditing} />
        </Column>
      </Row>
      <Row>
        <Column className='col-4'>
          <Zip form={form} disabled={!isEditing} />
        </Column>
        <Column className='col-4'>
          <BirthDate form={form} disabled={!isEditing} />
        </Column>
        <Column className='col-4'>
          <EmergencyPhone form={form} disabled={!isEditing} />
        </Column>
      </Row>
      <Row>
        <Column className='col-4'>
          <BusinessType form={form} disabled={!isEditing} />
        </Column>
        <VisibilityToggler visible={form.owner.businessType === EBusinessType.Llc}>
          <Column className='col-4'>
            <LLC form={form} disabled={!isEditing} />
          </Column>
        </VisibilityToggler>
        <Column className='col-4'>
          <TaxExempt form={form} disabled={!isEditing} />
        </Column>
      </Row>
      <VisibilityToggler visible={form.owner.taxExempt}>
        <Row>
          <Column className='col-4'>
            <Field label={RequestEditCaptions['Owner.ExemptPayeeCode']}>
              <StyledInputText
                // @ts-ignore
                value={form.owner.exemptPayeeCode}
                disabled={!isEditing}
                onChange={e => form.setOwner(old => {
                  return { ...old, exemptPayeeCode: e.target.value };
                })}
              />
            </Field>
          </Column>
          <Column className='col-4'>
            <Field label={RequestEditCaptions['Owner.FATCA']}>
              <StyledInputText
                // @ts-ignore
                value={form.owner.fatca}
                disabled={!isEditing}
                onChange={e => form.setOwner(old => {
                  return { ...old, fatca: e.target.value };
                })}
              />
            </Field>
          </Column>
        </Row>
      </VisibilityToggler>
    </BrowserView>
    <MobileView>
      <Row>
        <Column className='col-6'>
          <CompanyName form={form} disabled={!isEditing} />
        </Column>
        <Column>
          <DBA form={form} disabled={!isEditing} />
        </Column>
      </Row>
      <Row>
        <Column className='col-6'>
          <FirstName form={form} disabled={!isEditing} />
        </Column>
        <Column className='col-6'>
          <LastName form={form} disabled={!isEditing} />
        </Column>
      </Row>
      <Row>
        <Column className='col-6'>
          <Email form={form} disabled={!isEditing} />
        </Column>
        <Column className='col-6'>
          <Phones form={form} disabled={!isEditing} />
        </Column>
      </Row>
      <Row>
        <Column className='col-6'>
          <EmployerId form={form} disabled={!isEditing} />
        </Column>
        <Column className='col-6'>
          <Address form={form} disabled={!isEditing} />
        </Column>
      </Row>
      <Row>
        <Column className='col-6'>
          <City form={form} disabled={!isEditing} />
        </Column>
        <Column className='col-6'>
          <State form={form} disabled={!isEditing} />
        </Column>
      </Row>
      <Row>
        <Column className='col-6'>
          <Zip form={form} disabled={!isEditing} />
        </Column>
        <Column className='col-6'>
          <BirthDate form={form} disabled={!isEditing} />
        </Column>
      </Row>
      <Row>
        <Column className='col-6'>
          <EmergencyPhone form={form} disabled={!isEditing} />
        </Column>
        <Column className='col-6'>
          <BusinessType form={form} disabled={!isEditing} />
        </Column>
      </Row>
      <Row>
        <Column className='col-6'>
          <TaxExempt form={form} disabled={!isEditing} />
        </Column>
      </Row>
    </MobileView>
    <Header>Direct deposit form</Header>
    <BrowserView>
      <Row>
        <Column className='col-4'>
          <DepositCompanyName form={form} disabled={!isEditing} />
        </Column>
        <Column className='col-4'>
          <DepositCityStateZip form={form} disabled={!isEditing} />
        </Column>
        <Column className='col-4'>
          <DepositAddress form={form} disabled={!isEditing} />
        </Column>
      </Row>
      <Row>
        <Column className='col-4'>
          <BlankName form={form} disabled={!isEditing} />
        </Column>
        <Column className='col-4'>
          <AccountingNumber form={form} disabled={!isEditing} />
        </Column>
        <Column className='col-4'>
          <RoutingNumber form={form} disabled={!isEditing} />
        </Column>
      </Row>
    </BrowserView>
    <MobileView>
      <Row>
        <Column className='col-6'>
          <DepositCompanyName form={form} disabled={!isEditing} />
        </Column>
        <Column className='col-6'>
          <DepositCityStateZip form={form} disabled={!isEditing} />
        </Column>
      </Row>
      <Row>
        <Column className='col-6'>
          <DepositAddress form={form} disabled={!isEditing} />
        </Column>
        <Column className='col-6'>
          <BlankName form={form} disabled={!isEditing} />
        </Column>
      </Row>
      <Row>
        <Column className='col-6'>
          <AccountingNumber form={form} disabled={!isEditing} />
        </Column>
        <Column className='col-6'>
          <RoutingNumber form={form} disabled={!isEditing} />
        </Column>
      </Row>
    </MobileView>
    <Header>Documents</Header>
    <BrowserView>
      <Row>
        <Column className='col-12'>
          <W9 form={form} disabled={!isEditing} filePreviewWidget={filePreviewWidget}/>
        </Column>
      </Row>
      <Row>
        <Column className='col-4'>
          <VoidCheck form={form} disabled={!isEditing} collectivePreviewWidget={filePreviewWidget}/>
        </Column>
        <Column className='col-4'>
          <Contract form={form} disabled={!isEditing} collectivePreviewWidget={filePreviewWidget}/>
        </Column>
      </Row>
    </BrowserView>
    <MobileView>
      <Row>
        <Column className='col-12'>
          <W9 form={form} disabled={!isEditing} filePreviewWidget={filePreviewWidget}/>
        </Column>
      </Row>
      <Row>
        <Column className='col-6'>
          <VoidCheck form={form} disabled={!isEditing} collectivePreviewWidget={filePreviewWidget}/>
        </Column>
      </Row>
      <Row>
        <Column className='col-6'>
          <Contract form={form} disabled={!isEditing} collectivePreviewWidget={filePreviewWidget}/>
        </Column>
      </Row>
    </MobileView>
    <BrowserView>
      <footer className='flex justify-content-end w-full gap-3 pb-3'>
        <Button
          label='Save'
          className='p-button-success'
          onClick={form.save}
        />
      </footer>
    </BrowserView>
    <CenteredSpinner visible={form.loading} />
    <LogisticsFilePreview filePreviewWidget={filePreviewWidget} />
  </UniversalRequestCard>
}

// function Template(props: {form: RequestEditPublicWidget, disabled: boolean}) {
//   const { form, disabled } = props;
// }
function CompanyName(props: {form: RequestEditPublicWidget, disabled: boolean}) {
  const { form, disabled } = props;
  return <Field label={RequestEditCaptions['Owner.CompanyName']} required>
    <StyledInputText
      // @ts-ignore
      value={form.owner.companyName}
      disabled={disabled}
      onChange={e => form.setOwner(old => {
        return { ...old, companyName: e.target.value };
      })}
    />
    <ErrorBox>{form.ownerValidation.errors.companyName}</ErrorBox>
  </Field>
}

function DBA(props: {form: RequestEditPublicWidget, disabled: boolean}) {
  const { form, disabled } = props;
  return <Field label={RequestEditCaptions['Owner.BusinessAs']}>
    <StyledInputText
      // @ts-ignore
      value={form.owner.businessAs}
      disabled={disabled}
      onChange={e => form.setOwner(old => {
        return { ...old, businessAs: e.target.value };
      })}
    />
  </Field>
}

function RingDate(props: {form: RequestEditPublicWidget, disabled: boolean}) {
  const { form } = props;

  return <Field label={RequestEditCaptions.RingDate}>
    <LogisticsCalendar
      value={form.request.ringDate}
      disabled
      onChange={newValue => form.setRequest(old => {
        return {
          ...old,
          ringDate: newValue,
          nextRingDate: dayjs(newValue).add(2, 'day').toDate()
        }
      })}
    />
  </Field>
}

function NextRingDate(props: {form: RequestEditPublicWidget, disabled: boolean}) {
  const { form } = props;

  return <Field label={RequestEditCaptions.NextRingDate} required>
    <LogisticsCalendar
      value={form.request.nextRingDate}
      onChange={emptyArrowFunction}
      disabled
    />
    <ErrorBox>{form.requestValidation.errors.nextRingDate}</ErrorBox>
  </Field>
}

function Dispatcher(props: {form: RequestEditPublicWidget, disabled: boolean}) {
  const { form } = props;

  return <Field label={RequestEditCaptions.User} required>
    <EditableDropdown
      options={form.managers}
      optionLabel='firstName'
      renderOption={userFullName}
      renderSelected={userFullName}
      renderEmptyOption={() => '-'}
      renderEmptySelected={() => '-'}
      value={form.request.user}
      disabled
      onChange={newValue => form.setRequest(old => {
        return { ...old, user: newValue };
      })}
    />
    <ErrorBox>{form.requestValidation.errors.user}</ErrorBox>
  </Field>
}

function Comment(props: {form: RequestEditPublicWidget, disabled: boolean}) {
  const { form } = props;

  return <Field label={RequestEditCaptions.Comment}>
    <StyledInputText
      // @ts-ignore
      value={form.request.comment}
      disabled
      onChange={e => form.setRequest(old => {
        return { ...old, comment: e.target.value };
      })}
    />
  </Field>
}

function FirstName(props: {form: RequestEditPublicWidget, disabled: boolean}) {
  const { form, disabled } = props;

  return <Field label={RequestEditCaptions['Owner.FirstName']} required>
    <StyledInputText
      // @ts-ignore
      value={form.owner.firstName}
      disabled={disabled}
      onChange={e => form.setOwner(old => {
        return { ...old, firstName: e.target.value };
      })}
    />
    <ErrorBox>{form.ownerValidation.errors.firstName}</ErrorBox>
  </Field>
}

function LastName(props: {form: RequestEditPublicWidget, disabled: boolean}) {
  const { form, disabled } = props;

  return <Field label={RequestEditCaptions['Owner.LastName']} required>
    <StyledInputText
      // @ts-ignore
      value={form.owner.lastName}
      disabled={disabled}
      onChange={e => form.setOwner(old => {
        return { ...old, lastName: e.target.value };
      })}
    />
    <ErrorBox>{form.ownerValidation.errors.lastName}</ErrorBox>
  </Field>
}

function Email(props: {form: RequestEditPublicWidget, disabled: boolean}) {
  const { form, disabled } = props;

  return <Field label={RequestEditCaptions['Owner.Email']} required>
    <StyledInputText
      // @ts-ignore
      value={form.owner.email}
      disabled={disabled}
      onChange={e => form.setOwner(old => {
        return { ...old, email: e.target.value };
      })}
    />
    <ErrorBox>{form.ownerValidation.errors.email}</ErrorBox>
  </Field>
}

function Phones(props: {form: RequestEditPublicWidget, disabled: boolean}) {
  const { form, disabled } = props;

  return <Field label={RequestEditCaptions['Owner.Phones']} required>
    <LogisticsPhoneInput
      value={getPhone(form.owner)}
      country={'us'}
      disabled={disabled}
      onChange={number => form.setOwner(old => {
        return { ...old, phones: setPhone(old, number) };
      })}
    />
    <ErrorBox>{form.ownerValidation.errors.phones}</ErrorBox>
  </Field>
}

function EmployerId(props: {form: RequestEditPublicWidget, disabled: boolean}) {
  const { form, disabled } = props;

  return <Field label={RequestEditCaptions['Owner.EmployerId']} required>
    <StyledInputMask
      // @ts-ignore
      value={form.owner.employerId}
      mask='99-9999999'
      placeholder='__-_______'
      disabled={disabled}
      onChange={e => form.setOwner(old => {
        return { ...old, employerId: e.target.value };
      })}
    />
    <ErrorBox>{form.ownerValidation.errors.employerId}</ErrorBox>
  </Field>
}

function Address(props: {form: RequestEditPublicWidget, disabled: boolean}) {
  const { form, disabled } = props;

  return <Field label={RequestEditCaptions['Owner.Address']} required>
    <StyledInputText
      // @ts-ignore
      value={form.owner.address}
      disabled={disabled}
      onChange={e => form.setOwner(old => {
        return { ...old, address: e.target.value };
      })}
    />
    <ErrorBox>{form.ownerValidation.errors.address}</ErrorBox>
  </Field>
}

function City(props: {form: RequestEditPublicWidget, disabled: boolean}) {
  const { form, disabled } = props;

  return <Field label={RequestEditCaptions['Owner.City']} required>
    <StyledInputText
      // @ts-ignore
      value={form.owner.city}
      disabled={disabled}
      onChange={e => form.setOwner(old => {
        return { ...old, city: e.target.value };
      })}
    />
    <ErrorBox>{form.ownerValidation.errors.city}</ErrorBox>
  </Field>
}

function State(props: {form: RequestEditPublicWidget, disabled: boolean}) {
  const { form, disabled } = props;

  return <Field label={RequestEditCaptions['Owner.State']} required>
    <EditableDropdown
      options={form.states}
      optionLabel='name'
      renderOption={state => state.name}
      renderSelected={state => state?.name || ''}
      renderEmptyOption={() => '-'}
      renderEmptySelected={() => '-'}
      value={form.owner.state}
      filter
      disabled={disabled}
      onChange={newValue => form.setOwner(old => {
        return { ...old, state: newValue };
      })}
    />
    <ErrorBox>{form.ownerValidation.errors.state}</ErrorBox>
  </Field>
}

function Zip(props: {form: RequestEditPublicWidget, disabled: boolean}) {
  const { form, disabled } = props;

  return <Field label={RequestEditCaptions['Owner.Zip']} required>
    <StyledInputText
      // @ts-ignore
      value={form.owner.zip}
      disabled={disabled}
      onChange={e => form.setOwner(old => {
        return { ...old, zip: e.target.value };
      })}
    />
    <ErrorBox>{form.ownerValidation.errors.zip}</ErrorBox>
  </Field>
}

function BirthDate(props: {form: RequestEditPublicWidget, disabled: boolean}) {
  const { form, disabled } = props;

  return <Field label={RequestEditCaptions['Owner.Birth']}>
    <LogisticsDateOnly
      value={form.owner.birth}
      disabled={disabled}
      onChange={newValue => form.setOwner(old => {
        return { ...old, birth: newValue };
      })}
    />
  </Field>
}

function EmergencyPhone(props: {form: RequestEditPublicWidget, disabled: boolean}) {
  const { form, disabled } = props;

  return  <Field label={RequestEditCaptions['Owner.EmergencyPhone']}>
    <LogisticsPhoneInput
      value={form.owner.emergencyPhone}
      country={'us'}
      disabled={disabled}
      onChange={number => form.setOwner(old => {
        return { ...old, emergencyPhone: number };
      })}
    />
  </Field>
}

function BusinessType(props: {form: RequestEditPublicWidget, disabled: boolean}) {
  const { form, disabled } = props;

  return <Field label={RequestEditCaptions['Owner.BusinessType']} required>
    <EditableDropdown
      options={businessTypes}
      optionLabel='name'
      renderOption={x => x.name}
      renderSelected={x => x?.name || ''}
      renderEmptySelected={() => '-'}
      value={businessTypes.find(x => x.id === form.owner.businessType)}
      disabled={disabled}
      onChange={newValue => form.setOwner(old => {
        return { ...old, businessType: newValue.id };
      })}
    />
    <ErrorBox>{form.ownerValidation.errors.businessType}</ErrorBox>.
  </Field>
}

function LLC(props: {form: RequestEditPublicWidget, disabled: boolean}) {
  const { form, disabled } = props;

  return <Field label={RequestEditCaptions['Owner.LLC']}>
    <EditableDropdown
      options={LLCType}
      optionLabel='name'
      renderOption={x => x.name}
      renderSelected={x => x?.name || ''}
      renderEmptySelected={() => '-'}
      value={LLCType.find(x => x.id === form.owner.llc)}
      disabled={disabled}
      onChange={newValue => form.setOwner(old => {
        return { ...old, llc: newValue.id };
      })}
    />
    <ErrorBox>{form.ownerValidation.errors.llc}</ErrorBox>
  </Field>
}

function TaxExempt(props: {form: RequestEditPublicWidget, disabled: boolean}) {
  const { form, disabled } = props;

  return <Field label={RequestEditCaptions['Owner.TaxExempt']}>
    <Checkbox
      checked={form.owner.taxExempt}
      disabled={disabled}
      onChange={e => form.setOwner(old => {
        return { ...old, taxExempt: e.checked }
      })}
    />
  </Field>
}

function DepositCompanyName(props: {form: RequestEditPublicWidget, disabled: boolean}) {
  const { form, disabled } = props;

  return <Field label={RequestEditCaptions['Owner.DepositCompanyName']}>
    <StyledInputText
      // @ts-ignore
      value={form.owner.depositCompanyName}
      disabled={disabled}
      onChange={e => form.setOwner(old => {
        return { ...old, depositCompanyName: e.target.value };
      })}
    />
  </Field>
}

function DepositCityStateZip(props: {form: RequestEditPublicWidget, disabled: boolean}) {
  const { form, disabled } = props;

  return <Field label={RequestEditCaptions['Owner.DepositCityStateZip']}>
    <StyledInputText
      // @ts-ignore
      value={form.owner.depositCityStateZip}
      disabled={disabled}
      onChange={e => form.setOwner(old => {
        return { ...old, depositCityStateZip: e.target.value };
      })}
    />
  </Field>
}

function DepositAddress(props: {form: RequestEditPublicWidget, disabled: boolean}) {
  const { form, disabled } = props;

  return <Field label={RequestEditCaptions['Owner.DepositAddress']}>
    <StyledInputText
      // @ts-ignore
      value={form.owner.depositAddress}
      disabled={disabled}
      onChange={e => form.setOwner(old => {
        return { ...old, depositAddress: e.target.value };
      })}
    />
  </Field>
}

function BlankName(props: {form: RequestEditPublicWidget, disabled: boolean}) {
  const { form, disabled } = props;

  return <Field label={RequestEditCaptions['Owner.BlankName']}>
    <StyledInputText
      // @ts-ignore
      value={form.owner.blankName}
      disabled={disabled}
      onChange={e => form.setOwner(old => {
        return { ...old, blankName: e.target.value };
      })}
    />
  </Field>
}
function AccountingNumber(props: {form: RequestEditPublicWidget, disabled: boolean}) {
  const { form, disabled } = props;

  return <Field label={RequestEditCaptions['Owner.AccountingNumber']}>
    <StyledInputText
      // @ts-ignore
      value={form.owner.accountingNumber}
      disabled={disabled}
      onChange={e => form.setOwner(old => {
        return { ...old, accountingNumber: e.target.value };
      })}
    />
  </Field>
}

function RoutingNumber(props: {form: RequestEditPublicWidget, disabled: boolean}) {
  const { form, disabled } = props;

  return <Field label={RequestEditCaptions['Owner.RoutingNumber']}>
    <StyledInputText
      // @ts-ignore
      value={form.owner.routingNumber}
      disabled={disabled}
      onChange={e => form.setOwner(old => {
        return { ...old, routingNumber: e.target.value };
      })}
    />
  </Field>
}

function W9(props: {form: RequestEditPublicWidget, disabled: boolean, filePreviewWidget: FilePreviewWidget}) {
  const { form, disabled, filePreviewWidget} = props;

  return <OwnerDocumentsTable
    ownerDocuments={form.owner.ownerDocuments}
    filePreviewWidget={filePreviewWidget}
    onChange={form.setOwner}
    disabled={disabled}
  />
}

function VoidCheck(props: {form: RequestEditPublicWidget, disabled: boolean, collectivePreviewWidget: FilePreviewWidget}) {
  const { form, disabled, collectivePreviewWidget} = props;

  return <Field label={RequestEditCaptions['Owner.VoidCheck']}>
    <LogisticsFilesUpload
      value={form.owner.voidCheck}
      disabled={disabled}
      filePreviewWidget={collectivePreviewWidget}
      onChange={newFiles => form.setOwner(old => {
        return { ...old, voidCheck: newFiles };
      })}
    />
  </Field>
}

function Contract(props: {form: RequestEditPublicWidget, disabled: boolean, collectivePreviewWidget: FilePreviewWidget}) {
  const { form, disabled, collectivePreviewWidget} = props;

  return  <Field label={RequestEditCaptions['Owner.Contract']}>
    <LogisticsFilesUpload
      value={form.owner.contract}
      disabled={disabled}
      filePreviewWidget={collectivePreviewWidget}
      onChange={newFiles => form.setOwner(old => {
        return { ...old, contract: newFiles };
      })}
    />
  </Field>
}

function UnitsBlock(props: { form: RequestEditPublicWidget }) {
  const { form } = props;
  const header = 'Units';

  return <UniversalCard header={header}>
    <BrowserView>
      <Header>{header}</Header>
    </BrowserView>
    <RequestUnitList
      request={form.request}
      vehicles={form.request.vehicles}
      setVehicle={(updatedVehicle, forDeletion) => form.setRequest(old => {
        if (!updatedVehicle) return old;

        let oldVehicles = [...(old.vehicles || [])];
        const vehiclesWithoutUpdatedVehicle = oldVehicles
          .filter(v => v.id && v.id !== updatedVehicle.id);
        const newVehicles = forDeletion ? vehiclesWithoutUpdatedVehicle : vehiclesWithoutUpdatedVehicle.concat(updatedVehicle);
        return {
          ...old,
          vehicles: newVehicles,
        }
      })}
    />
  </UniversalCard>
}

function DriversBlock(props: { form: RequestEditPublicWidget}) {
  const { form } = props;

  return <UniversalCard header={'Drivers'}>
    <BrowserView>
      <Header>Drivers</Header>
    </BrowserView>
    <RequestDriverList
      request={form.request}
      drivers={form.request.drivers}
      setDriver={updatedDriver => form.setRequest(old => {
        if (!updatedDriver) return old;

        let newDrivers = [...(old.drivers || [])];
        return {
          ...old,
          drivers: newDrivers
            .filter(d => d.id && d.id !== updatedDriver.id)
            .concat(updatedDriver),
        }
      })}
    />
  </UniversalCard>
}

function SignatureBlock(props: { form: RequestEditPublicWidget}) {
  const { form } = props;
  const canvasRef = useRef<ReactSketchCanvasRef>(null);
  const [blankSignatureError, setBlankSignatureError] = useState('');

  const saveSignature = async () => {
    const sketchingTime = await canvasRef!.current!.getSketchingTime();
    if (sketchingTime === 0) {
      setBlankSignatureError('This value should not be blank');
      return;
    }

    const imageUrl = await canvasRef!.current!.exportImage('png');
    const imageResponse = await fetch(imageUrl);
    const imageBlob = await imageResponse.blob();
    const w9ForSigning = form.owner.ownerDocuments
      ?.filter(x => !x.w9Signature)
      .map(x => x.id!) || [];
    await apiFactory(RegistrationApi)
      .apiRegistrationSignDocumentsPost({
        ownerId: form.owner.id,
        signature: imageBlob,
        ownerDocumentIds: w9ForSigning,
      });
    showSuccessToast('Documents were successfully signed');
    await form.loadFormData();
  };

  const hasUnsignedDocuments =
    !form.owner?.signature
    || form.owner?.ownerDocuments?.some(x => !x.w9Signature);

  return <VisibilityToggler visible={hasUnsignedDocuments}>
    <Column className='col-12 p-0'>
      <Panel header='Documents for signature' toggleable className='mb-1'>
        {form.documents?.map(document => {
          return <MobilePanel header={document.name} toggleable className='mb-1' key={document.url}>
            <div className='grid w-full m-0'>
              <Column className='col-12'>
                <LogisticsPdf url={document.url} />
              </Column>
            </div>
          </MobilePanel>
        })}
      </Panel>
      <Panel header='Place for signature'>
        <Row>
          <Column>
            <div>* Your signature will only be used to sign the contract,</div>
            <div>and will not be stored</div>
          </Column>
        </Row>
        <Row>
          <Column className='col-12'>
            <Field label='Please sign below' required><></></Field>
            <ErrorBox>{blankSignatureError}</ErrorBox>
            <LogisticsCanvas
              width='100%'
              height='300px'
              ref={canvasRef}
              onChange={() => {
                if (blankSignatureError) {
                  setBlankSignatureError('');
                }
              }}
            />
          </Column>
        </Row>
        <Row>
          <Column className='col-12'>
            <AntButton
              type="primary"
              loading={form.loading}
              onClick={saveSignature}
              className='w-full'
            >
              Save
            </AntButton>
          </Column>
        </Row>
      </Panel>
    </Column>
  </VisibilityToggler>
}
export default RequestEditPublic;