/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EDriverOrder } from './EDriverOrder';
import {
    EDriverOrderFromJSON,
    EDriverOrderFromJSONTyped,
    EDriverOrderToJSON,
} from './EDriverOrder';
import type { EGender } from './EGender';
import {
    EGenderFromJSON,
    EGenderFromJSONTyped,
    EGenderToJSON,
} from './EGender';
import type { LogisticsFile } from './LogisticsFile';
import {
    LogisticsFileFromJSON,
    LogisticsFileFromJSONTyped,
    LogisticsFileToJSON,
} from './LogisticsFile';
import type { Request } from './Request';
import {
    RequestFromJSON,
    RequestFromJSONTyped,
    RequestToJSON,
} from './Request';
import type { State } from './State';
import {
    StateFromJSON,
    StateFromJSONTyped,
    StateToJSON,
} from './State';
import type { Tracking } from './Tracking';
import {
    TrackingFromJSON,
    TrackingFromJSONTyped,
    TrackingToJSON,
} from './Tracking';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';
import type { Vehicle } from './Vehicle';
import {
    VehicleFromJSON,
    VehicleFromJSONTyped,
    VehicleToJSON,
} from './Vehicle';

/**
 * 
 * @export
 * @interface Driver
 */
export interface Driver {
    /**
     * 
     * @type {number}
     * @memberof Driver
     */
    externalId?: number | null;
    /**
     * 
     * @type {EGender}
     * @memberof Driver
     */
    gender?: EGender;
    /**
     * 
     * @type {string}
     * @memberof Driver
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Driver
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Driver
     */
    driverLogin?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Driver
     */
    address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Driver
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Driver
     */
    zip?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Driver
     */
    email?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Driver
     */
    phones?: Array<string> | null;
    /**
     * 
     * @type {Date}
     * @memberof Driver
     */
    dateHired?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof Driver
     */
    birthDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof Driver
     */
    licenceExpires?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Driver
     */
    emergencyContactName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Driver
     */
    emergencyContactPhone?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Driver
     */
    active?: boolean | null;
    /**
     * 
     * @type {EDriverOrder}
     * @memberof Driver
     */
    order?: EDriverOrder;
    /**
     * 
     * @type {Array<LogisticsFile>}
     * @memberof Driver
     */
    drRecordFile?: Array<LogisticsFile> | null;
    /**
     * 
     * @type {Array<LogisticsFile>}
     * @memberof Driver
     */
    licenseFile?: Array<LogisticsFile> | null;
    /**
     * 
     * @type {string}
     * @memberof Driver
     */
    ssn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Driver
     */
    licenseNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Driver
     */
    licenseType?: string | null;
    /**
     * 
     * @type {Array<LogisticsFile>}
     * @memberof Driver
     */
    passport?: Array<LogisticsFile> | null;
    /**
     * 
     * @type {string}
     * @memberof Driver
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Driver
     */
    createdDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Driver
     */
    lastModifiedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Driver
     */
    lastModifiedDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof Driver
     */
    vehicleId?: number | null;
    /**
     * 
     * @type {Vehicle}
     * @memberof Driver
     */
    vehicle?: Vehicle;
    /**
     * 
     * @type {number}
     * @memberof Driver
     */
    stateId?: number | null;
    /**
     * 
     * @type {State}
     * @memberof Driver
     */
    state?: State;
    /**
     * 
     * @type {number}
     * @memberof Driver
     */
    licenseStateId?: number | null;
    /**
     * 
     * @type {State}
     * @memberof Driver
     */
    licenseState?: State;
    /**
     * 
     * @type {number}
     * @memberof Driver
     */
    requestId?: number | null;
    /**
     * 
     * @type {Request}
     * @memberof Driver
     */
    request?: Request;
    /**
     * 
     * @type {boolean}
     * @memberof Driver
     */
    allowBid?: boolean | null;
    /**
     * 
     * @type {Array<Tracking>}
     * @memberof Driver
     */
    trackings?: Array<Tracking> | null;
    /**
     * 
     * @type {User}
     * @memberof Driver
     */
    user?: User;
    /**
     * 
     * @type {string}
     * @memberof Driver
     */
    userId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Driver
     */
    radius?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof Driver
     */
    showOffers?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof Driver
     */
    id?: number;
}

/**
 * Check if a given object implements the Driver interface.
 */
export function instanceOfDriver(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DriverFromJSON(json: any): Driver {
    return DriverFromJSONTyped(json, false);
}

export function DriverFromJSONTyped(json: any, ignoreDiscriminator: boolean): Driver {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'externalId': !exists(json, 'externalId') ? undefined : json['externalId'],
        'gender': !exists(json, 'gender') ? undefined : EGenderFromJSON(json['gender']),
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'driverLogin': !exists(json, 'driverLogin') ? undefined : json['driverLogin'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'zip': !exists(json, 'zip') ? undefined : json['zip'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phones': !exists(json, 'phones') ? undefined : json['phones'],
        'dateHired': !exists(json, 'dateHired') ? undefined : (json['dateHired'] === null ? null : new Date(json['dateHired'])),
        'birthDate': !exists(json, 'birthDate') ? undefined : (json['birthDate'] === null ? null : new Date(json['birthDate'])),
        'licenceExpires': !exists(json, 'licenceExpires') ? undefined : (json['licenceExpires'] === null ? null : new Date(json['licenceExpires'])),
        'emergencyContactName': !exists(json, 'emergencyContactName') ? undefined : json['emergencyContactName'],
        'emergencyContactPhone': !exists(json, 'emergencyContactPhone') ? undefined : json['emergencyContactPhone'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'order': !exists(json, 'order') ? undefined : EDriverOrderFromJSON(json['order']),
        'drRecordFile': !exists(json, 'drRecordFile') ? undefined : (json['drRecordFile'] === null ? null : (json['drRecordFile'] as Array<any>).map(LogisticsFileFromJSON)),
        'licenseFile': !exists(json, 'licenseFile') ? undefined : (json['licenseFile'] === null ? null : (json['licenseFile'] as Array<any>).map(LogisticsFileFromJSON)),
        'ssn': !exists(json, 'ssn') ? undefined : json['ssn'],
        'licenseNumber': !exists(json, 'licenseNumber') ? undefined : json['licenseNumber'],
        'licenseType': !exists(json, 'licenseType') ? undefined : json['licenseType'],
        'passport': !exists(json, 'passport') ? undefined : (json['passport'] === null ? null : (json['passport'] as Array<any>).map(LogisticsFileFromJSON)),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (json['createdDate'] === null ? null : new Date(json['createdDate'])),
        'lastModifiedBy': !exists(json, 'lastModifiedBy') ? undefined : json['lastModifiedBy'],
        'lastModifiedDate': !exists(json, 'lastModifiedDate') ? undefined : (json['lastModifiedDate'] === null ? null : new Date(json['lastModifiedDate'])),
        'vehicleId': !exists(json, 'vehicleId') ? undefined : json['vehicleId'],
        'vehicle': !exists(json, 'vehicle') ? undefined : VehicleFromJSON(json['vehicle']),
        'stateId': !exists(json, 'stateId') ? undefined : json['stateId'],
        'state': !exists(json, 'state') ? undefined : StateFromJSON(json['state']),
        'licenseStateId': !exists(json, 'licenseStateId') ? undefined : json['licenseStateId'],
        'licenseState': !exists(json, 'licenseState') ? undefined : StateFromJSON(json['licenseState']),
        'requestId': !exists(json, 'requestId') ? undefined : json['requestId'],
        'request': !exists(json, 'request') ? undefined : RequestFromJSON(json['request']),
        'allowBid': !exists(json, 'allowBid') ? undefined : json['allowBid'],
        'trackings': !exists(json, 'trackings') ? undefined : (json['trackings'] === null ? null : (json['trackings'] as Array<any>).map(TrackingFromJSON)),
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'radius': !exists(json, 'radius') ? undefined : json['radius'],
        'showOffers': !exists(json, 'showOffers') ? undefined : json['showOffers'],
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function DriverToJSON(value?: Driver | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'externalId': value.externalId,
        'gender': EGenderToJSON(value.gender),
        'firstName': value.firstName,
        'lastName': value.lastName,
        'driverLogin': value.driverLogin,
        'address': value.address,
        'city': value.city,
        'zip': value.zip,
        'email': value.email,
        'phones': value.phones,
        'dateHired': value.dateHired === undefined ? undefined : (value.dateHired === null ? null : value.dateHired.toISOString().substr(0,10)),
        'birthDate': value.birthDate === undefined ? undefined : (value.birthDate === null ? null : value.birthDate.toISOString().substr(0,10)),
        'licenceExpires': value.licenceExpires === undefined ? undefined : (value.licenceExpires === null ? null : value.licenceExpires.toISOString()),
        'emergencyContactName': value.emergencyContactName,
        'emergencyContactPhone': value.emergencyContactPhone,
        'active': value.active,
        'order': EDriverOrderToJSON(value.order),
        'drRecordFile': value.drRecordFile === undefined ? undefined : (value.drRecordFile === null ? null : (value.drRecordFile as Array<any>).map(LogisticsFileToJSON)),
        'licenseFile': value.licenseFile === undefined ? undefined : (value.licenseFile === null ? null : (value.licenseFile as Array<any>).map(LogisticsFileToJSON)),
        'ssn': value.ssn,
        'licenseNumber': value.licenseNumber,
        'licenseType': value.licenseType,
        'passport': value.passport === undefined ? undefined : (value.passport === null ? null : (value.passport as Array<any>).map(LogisticsFileToJSON)),
        'createdBy': value.createdBy,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate === null ? null : value.createdDate.toISOString()),
        'lastModifiedBy': value.lastModifiedBy,
        'lastModifiedDate': value.lastModifiedDate === undefined ? undefined : (value.lastModifiedDate === null ? null : value.lastModifiedDate.toISOString()),
        'vehicleId': value.vehicleId,
        'vehicle': VehicleToJSON(value.vehicle),
        'stateId': value.stateId,
        'state': StateToJSON(value.state),
        'licenseStateId': value.licenseStateId,
        'licenseState': StateToJSON(value.licenseState),
        'requestId': value.requestId,
        'request': RequestToJSON(value.request),
        'allowBid': value.allowBid,
        'trackings': value.trackings === undefined ? undefined : (value.trackings === null ? null : (value.trackings as Array<any>).map(TrackingToJSON)),
        'user': UserToJSON(value.user),
        'userId': value.userId,
        'radius': value.radius,
        'showOffers': value.showOffers,
        'id': value.id,
    };
}

