/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Bid } from './Bid';
import {
    BidFromJSON,
    BidFromJSONTyped,
    BidToJSON,
} from './Bid';
import type { DriverBid } from './DriverBid';
import {
    DriverBidFromJSON,
    DriverBidFromJSONTyped,
    DriverBidToJSON,
} from './DriverBid';
import type { ESource } from './ESource';
import {
    ESourceFromJSON,
    ESourceFromJSONTyped,
    ESourceToJSON,
} from './ESource';
import type { Point } from './Point';
import {
    PointFromJSON,
    PointFromJSONTyped,
    PointToJSON,
} from './Point';
import type { VehicleType } from './VehicleType';
import {
    VehicleTypeFromJSON,
    VehicleTypeFromJSONTyped,
    VehicleTypeToJSON,
} from './VehicleType';
import type { ViewedLoadedOrder } from './ViewedLoadedOrder';
import {
    ViewedLoadedOrderFromJSON,
    ViewedLoadedOrderFromJSONTyped,
    ViewedLoadedOrderToJSON,
} from './ViewedLoadedOrder';

/**
 * 
 * @export
 * @interface DeletedOrder
 */
export interface DeletedOrder {
    /**
     * 
     * @type {string}
     * @memberof DeletedOrder
     */
    orderNumber?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof DeletedOrder
     */
    receivedDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof DeletedOrder
     */
    contactPhone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeletedOrder
     */
    contactEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeletedOrder
     */
    pickUpAt?: string | null;
    /**
     * 
     * @type {Point}
     * @memberof DeletedOrder
     */
    pickUpAtPoint?: Point;
    /**
     * 
     * @type {Date}
     * @memberof DeletedOrder
     */
    pickUpDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof DeletedOrder
     */
    deliverTo?: string | null;
    /**
     * 
     * @type {Point}
     * @memberof DeletedOrder
     */
    deliverToPoint?: Point;
    /**
     * 
     * @type {Date}
     * @memberof DeletedOrder
     */
    deliveryDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof DeletedOrder
     */
    notes?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeletedOrder
     */
    link?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DeletedOrder
     */
    pieces?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DeletedOrder
     */
    weight?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeletedOrder
     */
    dimensions?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeletedOrder
     */
    stackable?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeletedOrder
     */
    hazardous?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeletedOrder
     */
    fastLoad?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeletedOrder
     */
    dockLevel?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof DeletedOrder
     */
    postingExpiryDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof DeletedOrder
     */
    miles?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DeletedOrder
     */
    milesUnit?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DeletedOrder
     */
    pays?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DeletedOrder
     */
    marketPays?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DeletedOrder
     */
    matchCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DeletedOrder
     */
    recentUnits?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DeletedOrder
     */
    rawData?: string | null;
    /**
     * 
     * @type {ESource}
     * @memberof DeletedOrder
     */
    source?: ESource;
    /**
     * 
     * @type {boolean}
     * @memberof DeletedOrder
     */
    read?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DeletedOrder
     */
    archive?: boolean | null;
    /**
     * 
     * @type {Date}
     * @memberof DeletedOrder
     */
    archivedDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof DeletedOrder
     */
    archivedBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeletedOrder
     */
    eta?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeletedOrder
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof DeletedOrder
     */
    createdDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof DeletedOrder
     */
    lastModifiedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof DeletedOrder
     */
    lastModifiedDate?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof DeletedOrder
     */
    asap?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DeletedOrder
     */
    asapDeliver?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof DeletedOrder
     */
    postedBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeletedOrder
     */
    postedByPhone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeletedOrder
     */
    messageId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeletedOrder
     */
    messageIdHeader?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeletedOrder
     */
    brokerage?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeletedOrder
     */
    liftgate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeletedOrder
     */
    waitAndReturn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeletedOrder
     */
    team?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeletedOrder
     */
    csa?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeletedOrder
     */
    sysRef?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeletedOrder
     */
    shipmentId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeletedOrder
     */
    jobDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeletedOrder
     */
    packaging?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DeletedOrder
     */
    deliverDirect?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof DeletedOrder
     */
    items?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeletedOrder
     */
    brokerMC?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeletedOrder
     */
    vehicleType?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof DeletedOrder
     */
    lastDriverBid?: Date | null;
    /**
     * 
     * @type {Array<VehicleType>}
     * @memberof DeletedOrder
     */
    vehicleTypes?: Array<VehicleType> | null;
    /**
     * 
     * @type {Array<Bid>}
     * @memberof DeletedOrder
     */
    bids?: Array<Bid> | null;
    /**
     * 
     * @type {Array<ViewedLoadedOrder>}
     * @memberof DeletedOrder
     */
    viewedLoadedOrders?: Array<ViewedLoadedOrder> | null;
    /**
     * 
     * @type {Array<DriverBid>}
     * @memberof DeletedOrder
     */
    driverBids?: Array<DriverBid> | null;
    /**
     * 
     * @type {number}
     * @memberof DeletedOrder
     */
    id?: number;
}

/**
 * Check if a given object implements the DeletedOrder interface.
 */
export function instanceOfDeletedOrder(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DeletedOrderFromJSON(json: any): DeletedOrder {
    return DeletedOrderFromJSONTyped(json, false);
}

export function DeletedOrderFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeletedOrder {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orderNumber': !exists(json, 'orderNumber') ? undefined : json['orderNumber'],
        'receivedDate': !exists(json, 'receivedDate') ? undefined : (json['receivedDate'] === null ? null : new Date(json['receivedDate'])),
        'contactPhone': !exists(json, 'contactPhone') ? undefined : json['contactPhone'],
        'contactEmail': !exists(json, 'contactEmail') ? undefined : json['contactEmail'],
        'pickUpAt': !exists(json, 'pickUpAt') ? undefined : json['pickUpAt'],
        'pickUpAtPoint': !exists(json, 'pickUpAtPoint') ? undefined : PointFromJSON(json['pickUpAtPoint']),
        'pickUpDate': !exists(json, 'pickUpDate') ? undefined : (json['pickUpDate'] === null ? null : new Date(json['pickUpDate'])),
        'deliverTo': !exists(json, 'deliverTo') ? undefined : json['deliverTo'],
        'deliverToPoint': !exists(json, 'deliverToPoint') ? undefined : PointFromJSON(json['deliverToPoint']),
        'deliveryDate': !exists(json, 'deliveryDate') ? undefined : (json['deliveryDate'] === null ? null : new Date(json['deliveryDate'])),
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'link': !exists(json, 'link') ? undefined : json['link'],
        'pieces': !exists(json, 'pieces') ? undefined : json['pieces'],
        'weight': !exists(json, 'weight') ? undefined : json['weight'],
        'dimensions': !exists(json, 'dimensions') ? undefined : json['dimensions'],
        'stackable': !exists(json, 'stackable') ? undefined : json['stackable'],
        'hazardous': !exists(json, 'hazardous') ? undefined : json['hazardous'],
        'fastLoad': !exists(json, 'fastLoad') ? undefined : json['fastLoad'],
        'dockLevel': !exists(json, 'dockLevel') ? undefined : json['dockLevel'],
        'postingExpiryDate': !exists(json, 'postingExpiryDate') ? undefined : (json['postingExpiryDate'] === null ? null : new Date(json['postingExpiryDate'])),
        'miles': !exists(json, 'miles') ? undefined : json['miles'],
        'milesUnit': !exists(json, 'milesUnit') ? undefined : json['milesUnit'],
        'pays': !exists(json, 'pays') ? undefined : json['pays'],
        'marketPays': !exists(json, 'marketPays') ? undefined : json['marketPays'],
        'matchCount': !exists(json, 'matchCount') ? undefined : json['matchCount'],
        'recentUnits': !exists(json, 'recentUnits') ? undefined : json['recentUnits'],
        'rawData': !exists(json, 'rawData') ? undefined : json['rawData'],
        'source': !exists(json, 'source') ? undefined : ESourceFromJSON(json['source']),
        'read': !exists(json, 'read') ? undefined : json['read'],
        'archive': !exists(json, 'archive') ? undefined : json['archive'],
        'archivedDate': !exists(json, 'archivedDate') ? undefined : (json['archivedDate'] === null ? null : new Date(json['archivedDate'])),
        'archivedBy': !exists(json, 'archivedBy') ? undefined : json['archivedBy'],
        'eta': !exists(json, 'eta') ? undefined : json['eta'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (json['createdDate'] === null ? null : new Date(json['createdDate'])),
        'lastModifiedBy': !exists(json, 'lastModifiedBy') ? undefined : json['lastModifiedBy'],
        'lastModifiedDate': !exists(json, 'lastModifiedDate') ? undefined : (json['lastModifiedDate'] === null ? null : new Date(json['lastModifiedDate'])),
        'asap': !exists(json, 'asap') ? undefined : json['asap'],
        'asapDeliver': !exists(json, 'asapDeliver') ? undefined : json['asapDeliver'],
        'postedBy': !exists(json, 'postedBy') ? undefined : json['postedBy'],
        'postedByPhone': !exists(json, 'postedByPhone') ? undefined : json['postedByPhone'],
        'messageId': !exists(json, 'messageId') ? undefined : json['messageId'],
        'messageIdHeader': !exists(json, 'messageIdHeader') ? undefined : json['messageIdHeader'],
        'brokerage': !exists(json, 'brokerage') ? undefined : json['brokerage'],
        'liftgate': !exists(json, 'liftgate') ? undefined : json['liftgate'],
        'waitAndReturn': !exists(json, 'waitAndReturn') ? undefined : json['waitAndReturn'],
        'team': !exists(json, 'team') ? undefined : json['team'],
        'csa': !exists(json, 'csa') ? undefined : json['csa'],
        'sysRef': !exists(json, 'sysRef') ? undefined : json['sysRef'],
        'shipmentId': !exists(json, 'shipmentId') ? undefined : json['shipmentId'],
        'jobDescription': !exists(json, 'jobDescription') ? undefined : json['jobDescription'],
        'packaging': !exists(json, 'packaging') ? undefined : json['packaging'],
        'deliverDirect': !exists(json, 'deliverDirect') ? undefined : json['deliverDirect'],
        'items': !exists(json, 'items') ? undefined : json['items'],
        'brokerMC': !exists(json, 'brokerMC') ? undefined : json['brokerMC'],
        'vehicleType': !exists(json, 'vehicleType') ? undefined : json['vehicleType'],
        'lastDriverBid': !exists(json, 'lastDriverBid') ? undefined : (json['lastDriverBid'] === null ? null : new Date(json['lastDriverBid'])),
        'vehicleTypes': !exists(json, 'vehicleTypes') ? undefined : (json['vehicleTypes'] === null ? null : (json['vehicleTypes'] as Array<any>).map(VehicleTypeFromJSON)),
        'bids': !exists(json, 'bids') ? undefined : (json['bids'] === null ? null : (json['bids'] as Array<any>).map(BidFromJSON)),
        'viewedLoadedOrders': !exists(json, 'viewedLoadedOrders') ? undefined : (json['viewedLoadedOrders'] === null ? null : (json['viewedLoadedOrders'] as Array<any>).map(ViewedLoadedOrderFromJSON)),
        'driverBids': !exists(json, 'driverBids') ? undefined : (json['driverBids'] === null ? null : (json['driverBids'] as Array<any>).map(DriverBidFromJSON)),
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function DeletedOrderToJSON(value?: DeletedOrder | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'orderNumber': value.orderNumber,
        'receivedDate': value.receivedDate === undefined ? undefined : (value.receivedDate === null ? null : value.receivedDate.toISOString()),
        'contactPhone': value.contactPhone,
        'contactEmail': value.contactEmail,
        'pickUpAt': value.pickUpAt,
        'pickUpAtPoint': PointToJSON(value.pickUpAtPoint),
        'pickUpDate': value.pickUpDate === undefined ? undefined : (value.pickUpDate === null ? null : value.pickUpDate.toISOString()),
        'deliverTo': value.deliverTo,
        'deliverToPoint': PointToJSON(value.deliverToPoint),
        'deliveryDate': value.deliveryDate === undefined ? undefined : (value.deliveryDate === null ? null : value.deliveryDate.toISOString()),
        'notes': value.notes,
        'link': value.link,
        'pieces': value.pieces,
        'weight': value.weight,
        'dimensions': value.dimensions,
        'stackable': value.stackable,
        'hazardous': value.hazardous,
        'fastLoad': value.fastLoad,
        'dockLevel': value.dockLevel,
        'postingExpiryDate': value.postingExpiryDate === undefined ? undefined : (value.postingExpiryDate === null ? null : value.postingExpiryDate.toISOString()),
        'miles': value.miles,
        'milesUnit': value.milesUnit,
        'pays': value.pays,
        'marketPays': value.marketPays,
        'matchCount': value.matchCount,
        'recentUnits': value.recentUnits,
        'rawData': value.rawData,
        'source': ESourceToJSON(value.source),
        'read': value.read,
        'archive': value.archive,
        'archivedDate': value.archivedDate === undefined ? undefined : (value.archivedDate === null ? null : value.archivedDate.toISOString()),
        'archivedBy': value.archivedBy,
        'eta': value.eta,
        'createdBy': value.createdBy,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate === null ? null : value.createdDate.toISOString()),
        'lastModifiedBy': value.lastModifiedBy,
        'lastModifiedDate': value.lastModifiedDate === undefined ? undefined : (value.lastModifiedDate === null ? null : value.lastModifiedDate.toISOString()),
        'asap': value.asap,
        'asapDeliver': value.asapDeliver,
        'postedBy': value.postedBy,
        'postedByPhone': value.postedByPhone,
        'messageId': value.messageId,
        'messageIdHeader': value.messageIdHeader,
        'brokerage': value.brokerage,
        'liftgate': value.liftgate,
        'waitAndReturn': value.waitAndReturn,
        'team': value.team,
        'csa': value.csa,
        'sysRef': value.sysRef,
        'shipmentId': value.shipmentId,
        'jobDescription': value.jobDescription,
        'packaging': value.packaging,
        'deliverDirect': value.deliverDirect,
        'items': value.items,
        'brokerMC': value.brokerMC,
        'vehicleType': value.vehicleType,
        'lastDriverBid': value.lastDriverBid === undefined ? undefined : (value.lastDriverBid === null ? null : value.lastDriverBid.toISOString()),
        'vehicleTypes': value.vehicleTypes === undefined ? undefined : (value.vehicleTypes === null ? null : (value.vehicleTypes as Array<any>).map(VehicleTypeToJSON)),
        'bids': value.bids === undefined ? undefined : (value.bids === null ? null : (value.bids as Array<any>).map(BidToJSON)),
        'viewedLoadedOrders': value.viewedLoadedOrders === undefined ? undefined : (value.viewedLoadedOrders === null ? null : (value.viewedLoadedOrders as Array<any>).map(ViewedLoadedOrderToJSON)),
        'driverBids': value.driverBids === undefined ? undefined : (value.driverBids === null ? null : (value.driverBids as Array<any>).map(DriverBidToJSON)),
        'id': value.id,
    };
}

