import {
  LoadedOrder,
  GeoApi, Offer, OffersApi, Bid,
} from '../../generated-api';
import { useEffect, useState, useCallback, useMemo} from 'react';
import { Button } from 'primereact/button';
import LoadedOrderVehiclesBlock from '../../components/LoadedOrderVehiclesBlock';
import { ModalParameters } from '../../shared';
import { useAppDispatch, useAppSelector } from '../../hooks';
import CenteredSpinner from '../../components/CenteredSpinner';
import { apiFactory } from '../../shared';
import LogisticsDialog from '../../components/LogisticsDialog';
import { showErrorToast, showToast } from '../../components/LogisticsToast';
import { getCurrentDispSetting, getSystemSetting } from '../settings/SettingsSlice';
import { updateEntity } from './LoadedOrdersSlice';
import StyledInputText from '../../components/form/StyledInputText';
import StyledInputNumber from '../../components/form/StyledInputNumber';
import ErrorBox from '../../components/form/ErrorBox';
import PricesBlock, { PricesData } from '../../components/prices/PricesBlock';
import MailEditor from './MailEditor';
import BidCheckboxes from './BidCheckboxes';

const getInitPrices = (data : LoadedOrder | undefined) : PricesData => {
  return {
    miles: data?.miles,
    profit: undefined
  }
}

export interface LoadedOrderCustomEditParameters extends ModalParameters<LoadedOrder> {
}

export const LoadedOrderCustomEdit = (props: LoadedOrderCustomEditParameters) => {
  const [loadedOrder, setLoadedOrder] = useState<LoadedOrder>(props.data);
  const [formBid,  setFormBid] = useState<Bid>({} as Bid);
  const [pricesData, setPricesData] = useState<PricesData>(getInitPrices(props.data));
  const [offers, setOffers] = useState<Offer[]>([]);
  const [loading, setLoading] = useState(props.loading);
  const [selectedUnit, setSelectedUnit] = useState<Offer>();
  const systemSettings = useAppSelector(state => state.settings.system);
  const dispatcherSettings = useAppSelector(state => state.settings.disp);
  const dispatch = useAppDispatch();
  const ordersList = useAppSelector(state => state.loadedOrder.entities);
  const [dispatchToError, setDispatchToError] = useState<string>();
  const [milesError, setMilesError] = useState<string>();
  const [customTemplate, setCustomTemplate] =
    useState(dispatcherSettings.bidEmailTemplate || systemSettings.bidEmailTemplateS);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filledTemplate, setFilledTemplate] = useState('');

  const rawTemplate = useMemo(() => {
    if (!selectedUnit) return '';

    return customTemplate ||
      dispatcherSettings.bidEmailTemplate ||
      systemSettings.bidEmailTemplateS ||
      '';
  }, [customTemplate, systemSettings.bidEmailTemplateS, dispatcherSettings.bidEmailTemplate, selectedUnit]);

  useEffect(() => {
    if (!props.visible) return;

    setLoading(true);
    setLoadedOrder(props.data);
    setPricesData(getInitPrices(props.data));

    const loadSystemSettings = systemSettings.bidEmailTemplateS && systemSettings.minProfit
      ? Promise.resolve()
      : dispatch(getSystemSetting({ id: 1 }));
    const loadDispatcherSettings = dispatcherSettings.bidEmailTemplate
      ? Promise.resolve()
      : dispatch(getCurrentDispSetting({}));
    const getUnits = apiFactory(OffersApi)
      .apiOffersSuitableForPointGet({...props.data.pickUpAtPoint });
    Promise.all([getUnits, loadSystemSettings, loadDispatcherSettings])
      .then(([loadedOffers]) => {
        setOffers(loadedOffers);
    }).catch(reason => {
      showErrorToast('Error on orders loading');
      console.error('Error on orders loading:', reason);
    })
    .finally(() => {
      setLoading(false);
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.visible]);

  const hide = (newData? : LoadedOrder) => {
    const updatedOrder = newData || loadedOrder;
    const listOrder = ordersList.find(x => x.id === updatedOrder.id);
    if (listOrder) {
      // Do not replace list entity with edit entity - projections are different.
      dispatch(updateEntity( { ...listOrder, bids: updatedOrder.bids }));
    }

    props.hide(updatedOrder);
    setLoading(false);
  };

  const handleOnHide = useCallback(() => {
    hide();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedOrder]);

  const onSelectionChange = (selection: Offer | undefined) => {
    setSelectedUnit(selection);

    if (selection && !selection.vehicleGroundMiles) {
      apiFactory(GeoApi).apiGeoRouteDistancePost({ twoPoints: {
        a: selection.vehicle?.availableGeo,
        b: props.data.pickUpAtPoint
      }}).then((distance) => {
        selection.vehicleGroundMiles = Number(distance);
        setSelectedUnit(selection);
      }).catch(() => {
        showToast({
          severity: 'error',
          summary: 'Error',
          detail: 'Error on getting route distance',
          life: 2000
        });
      });
    }
  };

  const onDeliverToBlur = async (e: React.FocusEvent<HTMLInputElement, Element>) => {
    setDispatchToError(undefined);
    setMilesError(undefined);
    const deliverTo = e.currentTarget.value;
    const api = apiFactory(GeoApi);
    if (deliverTo) {
      api.apiGeoGet({address: deliverTo})
        .then(deliverToPoint => {
          setLoadedOrder(lo => {return {...lo, deliverToPoint: deliverToPoint}});
          api.apiGeoRouteDistancePost({twoPoints: {a: loadedOrder.pickUpAtPoint, b: deliverToPoint}})
            .then(distance => {
              setLoadedOrder(lo => {return {...lo, miles: distance}});
              setPricesData(pd => {return {...pd, miles: distance}});
            })
            .catch(error => {
              setMilesError('Failed to calculate the distance between \'Pick-up at\' and \'Delivery to\' points');
              throw error;
            });
        })
        .catch(error => {
          setDispatchToError('Failed to calculate coordinates for the field');
          throw error;
        });
    }
  }

  function viewOnMapLink() {
    const destinationParameter = loadedOrder.deliverTo ? `&destination=${encodeURI(loadedOrder.deliverTo)}` : '';
    const link = `https://www.google.com/maps/dir/?api=1&origin=${encodeURI(loadedOrder.pickUpAt!)}${destinationParameter}`;
    return (
    <a
      className='loaded-order-map-link text-lg pl-4'
      target="_blank"
      rel="noreferrer"
      href={link}>
        View on Map
    </a>)
  }

  return (
    <LogisticsDialog
      visible={props.visible}
      onHide={handleOnHide}
      className='full-height-dialog w-11'>
      <div className='flex flex-row h-full'>
        <div style={{ flexBasis: '25%', maxWidth: '25%' }}>
          <div className='text-2xl font-bold'>Order</div>
          <div className='my-3 font-bold'>Pick-up at: {loadedOrder?.pickUpAt}</div>
          <div className='mt-3 font-bold'>Deliver to</div>
          <StyledInputText
            // @ts-ignore
            value={loadedOrder.deliverTo}
            onBlur={onDeliverToBlur}
            onChange={e => setLoadedOrder(lo => {
              return { ...lo, deliverTo: e.target.value };
            })}
          />
          <ErrorBox>{dispatchToError}</ErrorBox>
          <div className='mt-3 font-bold'>Miles</div>
          <StyledInputNumber
            // @ts-ignore
            value={loadedOrder.miles}
            onChange={e => {
              setLoadedOrder(lo => {
                return { ...lo, miles: e.value };
              });
              setPricesData(pd => {
                return { ...pd, miles: e.value };
              });
            }}
          />
          <ErrorBox>{milesError}</ErrorBox>
        </div>
        <div className='flex flex-grow-1 flex-column overflow-y-auto overflow-x-hidden'>
          <div className='flex-grow-0'>
            <LoadedOrderVehiclesBlock
              data={offers}
              loadedOrder={loadedOrder}
              selected={{ ...selectedUnit }}
              onSelectionChange={onSelectionChange}
              extraHeaderContent={viewOnMapLink()}
            />
          </div>
          <div className='grid'>
            <div className='px-3 col-4'>
              <p>
                <span className='text-2xl'>Broker email: The order has no contact email.</span>
              </p>
              <PricesBlock
                onPricesChange={(data) => setPricesData(Object.create(data))}
                minProfit={systemSettings.minProfit}
                data={pricesData}
              />
              <BidCheckboxes formBid={formBid} setFormBid={setFormBid} />
            </div>
            <div className='px-3 col-8'>
              <div className='h-30rem'>
                <MailEditor
                  rawTemplate={rawTemplate}
                  variables={{
                    Eta: loadedOrder.eta || '',
                    Dimensions: selectedUnit?.vehicle?.boxDimensions?.join('x') || loadedOrder.dimensions || '',
                    PayLoad: selectedUnit?.vehicle?.payload?.toString() || '',
                    McNumber: systemSettings.mcNumber?.toString() || '',
                    Miles: (selectedUnit?.vehicleGroundMiles || selectedUnit?.vehicleFlyMiles)?.toString() || '',
                    PriceForBroker: pricesData.priceForBroker?.toString() || '',
                    DockHigh: formBid.dockHigh ? 'Dock high' : '',
                    TtDrivers: formBid.ttDrivers ? 'TRUE TEAM DRIVERS' : '',
                    LiftGate: formBid.liftGate ? 'Lift gate' : '',
                    PalletJack: formBid.palletJack ? 'Pallet jack' : '',
                    DriverAssist: formBid.driverAssist ? 'Driver assist' : '',
                    FullPpe: formBid.fullPPE ? 'FULL PPE' : '',
                  }}
                  onRawTemplateChange={value => {
                    setCustomTemplate(value);
                  } }
                  onFilledTemplateChange={value => {
                    setFilledTemplate(value);
                  } }
                  showCopyAllToClipboardButton
                  />
              </div>
            </div>
          </div>
          <div className='flex justify-content-end'>
            <Button
              label='CLOSE'
              className='p-button-secondary min-w-min w-8rem h-4rem'
              onClick={handleOnHide}>
            </Button>
          </div>
        </div>
      </div>
      <CenteredSpinner visible={loading} />
    </LogisticsDialog>
  );
};

export default LoadedOrderCustomEdit;