import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import StyledInputText from '../../../components/form/StyledInputText';
import LogisticsDialog from '../../../components/LogisticsDialog';
import { showErrorToast, showSuccessToast } from '../../../components/LogisticsToast';
import { apiFactory, ModalParameters } from '../../../shared';
import copy from 'copy-to-clipboard';
import { RequestApi } from '../../../generated-api';


export interface RequestLinkParameters extends ModalParameters<number> {

}

function RequestLink(props : RequestLinkParameters) {
  const [requestLink, setRequestLink] = useState('');

  useEffect(() => {
    if (!props.visible) {
      return;
    }

    apiFactory(RequestApi)
      .apiRequestsBaseUrlForUpdateGet()
      .then(baseUrl => {
        setRequestLink(baseUrl + '/request/pub/' + props.data);
      })
      .catch(reason => {
        const error = 'failed to get request link';
        console.error(error, reason);
        showErrorToast(error);
      });
  }, [props.visible, props.data]);
  return <>
    <LogisticsDialog
      visible={props.visible}
      onHide={() => props.hide(undefined)}
      className='logistics-dialog-edit-form logistics-dialog-edit-form_loadable w-3'
    >
      <main className='logistics-dialog-edit-form__content'>
        <div className="grid">
          <div className="col-6">
            <header className='text-2xl w-full flex-wrap mb-2'>
              <span className='font-bold'>Link</span>
            </header>
          </div>
          <div className="col-6 flex flex-row-reverse">
          <Button icon='pi pi-times' className='p-button-rounded p-button-danger p-button-text' aria-label='Close' onClick={() => props.hide(undefined)}/>
          </div>
        </div>

        <div className='text-xl'>Link for Update</div>
        <div className='flex mb-4'>
          <StyledInputText
            value={requestLink}
            readOnly
          />
          <Button
            icon='pi pi-copy'
            className='p-button-lg p-button-text p-button-plain'
            onClick={() => {
              copy(requestLink);
              showSuccessToast('Copied to clipboard.');
            }}
          />
        </div>
      </main>
    </LogisticsDialog>
  </>;
}

export default RequestLink;