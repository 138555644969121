import dayjs, { Dayjs } from 'dayjs';
import { parseInt } from 'lodash';

const lsNumberFormat = /(.*?)(\d\d)-(\w{1,2})-(\d+)/;

type LsNumber = {
  abbreviation: string,
  week: Dayjs,
  consecutiveNumber: number,
};

export function lsNumberToString(number: LsNumber){
  if (!number) return '';

  return `${number.abbreviation}${number.week.format('gggg-w').substring(2)}-${number.consecutiveNumber}`;
}

export function stringToLsNumber(number: string | null | undefined) {
  if (!number) return {
    abbreviation: '',
    week: dayjs(),
    consecutiveNumber: 0,
  } as LsNumber;

  const splitted = number.match(lsNumberFormat)!;
  return {
    abbreviation: splitted[1],
    week: getFirstDayOfWeek(parseInt(splitted[3]), parseInt('20' + splitted[2])),
    consecutiveNumber: parseInt(splitted[4]),
  } as LsNumber;
}

// Took calculation based on week and year from here (ISO8601 version):
// https://stackoverflow.com/questions/16590500/calculate-date-from-week-number-in-javascript
function getFirstDayOfWeek(week: number, year: number): Dayjs {
  const simple = new Date(year, 0, 1 + (week - 1) * 7);
  const dow = simple.getDay();
  let ISOweekStart = simple;
  if (dow <= 4)
    ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
  else
    ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
  return dayjs(ISOweekStart);
}

export default LsNumber;