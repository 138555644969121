import React, { useEffect, useState } from 'react';
import { useHubConnection } from '../../../hooks/SignalR';
import {
  addUnreadBid,
  removeUnreadBid,
  setUnreadBidsCount,
  updateStoredNotificationsCount,
} from '../NotificationsSlice';
import { useAppDispatch } from '../../../hooks';
import { apiFactory } from '../../../shared';
import {
  BidsApi, DriverBid,
  ETrackingStatus,
  LoadedOrder,
  OwnerDocumentsSignedNotification,
  Tracking,
  Vehicle,
} from '../../../generated-api';
import { markAsRead, markAsUnRead, hasNewReplies, repliesViewed, readAll } from '../../bids/BidsSlice';
import { showToast } from '../../../components/LogisticsToast';
import useRights from '../../../hooks/RightsHook';
import RealtimeBidDto from '../RealtimeBidDto';
import TrackingEdit from '../../tracking/TrackingEdit';
import { TrackingNotification } from './components/TrackingNotification';
import { NotificationArrowButton } from './components/NotificationArrowButton';
import { ToastMessage } from 'primereact/toast';
import VehicleExpiryDto, { VehicleExpiryKind } from '../VehicleExpiryDto';
import dayjs from 'dayjs';
import UnitEdit from '../../hr/request-edit/units/UnitEdit';
import LoadedOrderEdit from '../../loaded-orders/LoadedOrderEdit/LoadedOrderEdit';
import BidEdit from '../../bids/BidEdit/BidEdit';
import BidReplyNotification from './components/BidReplyNotification';
import NewDriverBidNotification from './components/NewDriverBidNotification';
import DocumentsSignedNotification from './components/DocumentsSignedNotification';
import { DispStatusChangeNotification } from './components/DispStatusChangeNotification';

const PopupNotifications = () => {
  const dispatch = useAppDispatch();
  const hubConnection = useHubConnection();
  const [bidEditDialogs, setBidEditDialogs] = useState<JSX.Element[]>([]);
  const [trackingEditDialogs, setTrackingEditDialogs] = useState<JSX.Element[]>([]);
  const [vehicleEditDialogs, setVehicleEditDialogs] = useState<JSX.Element[]>([]);
  const [loadedOrderDialogs, setLoadedOrdersDialogs] = useState<JSX.Element[]>([]);

  const bidRights = useRights(security => security.bid)
  const onBidToastButtonClick = (bidId: number) => {
    const onBidEditHide = () => setBidEditDialogs(
      (prev) => {
        return [...prev].filter(x => x.key !== bidId.toString());
      }
    );
    apiFactory(BidsApi).apiBidsIdGet({ id: bidId })
      .then(response => {
        const newBidEdit = <BidEdit
          loading={false}
          hide={onBidEditHide}
          visible={true}
          data={response}
          key={bidId}
        />;
        setBidEditDialogs(prev => [...prev, newBidEdit]);
      }).catch(() => showToast( {
        severity: 'error',
        life: 2000,
        summary: 'Error',
        detail: 'Something went wrong'
      }));
  };

  const onTrackingToastButtonClick = (trackingId: number) => {
    const onTrackingEditHide = () => setTrackingEditDialogs(
      (previous) => {
        return [...previous].filter(x => x.key !== trackingId.toString());
      }
    );
    const trackingEdit = <TrackingEdit
      loading={false}
      hide={onTrackingEditHide}
      visible={true}
      data={{ id: trackingId} as Tracking}
      key={trackingId}
    />
    setTrackingEditDialogs(previous => [...previous, trackingEdit]);
  }

  const onVehicleToastButtonClick = (vehicleId: number) => {
    const onVehicleEditHide = () => setVehicleEditDialogs(
      (previous) => {
        return [...previous].filter(x => x.key !== vehicleId.toString());
      }
    );
    const vehicleEdit = <UnitEdit
      loading={false}
      hide={onVehicleEditHide}
      visible={true}
      data={{ id: vehicleId } as Vehicle}
      key={vehicleId}
    />
    setVehicleEditDialogs(previous => [...previous, vehicleEdit]);
  }

  const onNewDriverBidArrowClick = (driverBid: DriverBid) => {
    const loadedOrderId = driverBid?.loadedOrderId;
    if (!loadedOrderId) {
      console.error('empty loadedOrderId');
      return;
    }

    const onLoadedOrderHide = () => setLoadedOrdersDialogs(prev => {
      return [...prev].filter(x => x.key !== loadedOrderId.toString());
    });

    const loadedOrderEdit = <LoadedOrderEdit
      loading={false}
      hide={onLoadedOrderHide}
      visible={true}
      data={{id: loadedOrderId} as LoadedOrder}
      key={loadedOrderId}
    />

    setLoadedOrdersDialogs(prev => [...prev, loadedOrderEdit]);
  }

  const showBidNotification = (bidId: number) => {
    showToast({
      severity: 'success',
      life: 60 * 1000, // 1 min
      content: (<BidReplyNotification
        id={bidId}
        onArrowClick={() => onBidToastButtonClick(bidId)} />)});
  };


  const showTrackingNotification = (trackingId: number, trackingNumber: string, status: ETrackingStatus) => {
    showToast({
      severity: 'warn',
      life: 4 * 57 * 1000, // ~5 min
      content: (<TrackingNotification
        id={trackingId}
        number={trackingNumber}
        status={status}
        onArrowClick={() => onTrackingToastButtonClick(trackingId)}
      />),
    });
  }

  const showApprovedVehicleNotifications = (vehicleIds: number[]) => {
    var messages = vehicleIds.map(vehicleId => {
      return {
        severity: 'warn',
        life: 60 * 1000,
        content: (
          <div
            className='flex flex-row flex-grow-1 justify-content-evenly align-items-center text-2xl'
          >
            <i className='pi pi-check-circle text-2xl' />
            <span>New unit {vehicleId} has been added</span>
            <NotificationArrowButton onClick={() => onVehicleToastButtonClick(vehicleId)}>
              <i className='pi pi-angle-right text-2xl' />
            </NotificationArrowButton>
          </div>
        )
      } as ToastMessage
    });

    showToast(messages);
  };

  const showVehicleExpiryNotifications = (data: VehicleExpiryDto[]) => {
    var text: Record<VehicleExpiryKind, string> = {
      Insurance: 'Insurance expires',
      License: 'License Expiry',
    };

    var messages = data.map(vehicleExpiry => {
      return {
        severity: 'warn',
        life: 60 * 1000,
        content: (
          <div
            className='flex flex-row flex-grow-1 justify-content-evenly align-items-center text-2xl'
          >
            <i className='pi pi-check-circle text-2xl' />
            <span>{vehicleExpiry.vehicleId} {text[vehicleExpiry.expiryKind] || 'expires'} {dayjs(vehicleExpiry.expiryDate).tz().format('MM/DD/YYYY')}</span>
            <NotificationArrowButton onClick={() => onVehicleToastButtonClick(vehicleExpiry.vehicleId)}>
              <i className='pi pi-angle-right text-2xl' />
            </NotificationArrowButton>
          </div>
        )
      } as ToastMessage
    });

    showToast(messages);
  };

  const showNewDriverBidNotification = (driverBid: DriverBid) => {
    const message: ToastMessage = {
      severity: 'warn',
      life: 60 * 1000,
      content: (<NewDriverBidNotification
        driverBid={driverBid}
        onArrowClick={() => onNewDriverBidArrowClick(driverBid)}
      />)
    };

    showToast(message);
  }

  const showOwnerDocumentsSignedNotifications = (data: OwnerDocumentsSignedNotification) => {
    const message: ToastMessage = {
      severity: 'warn',
      life: 60 * 1000,
      content: (<DocumentsSignedNotification requestId={data.requestId} />),
    };

    showToast(message);
  }

  const showDispStatusChangeNotification = (trackingId: number, vehicleId: number) => {
    showToast({
      severity: 'warn',
      life: 10 * 1000, // 1 min
      content: (<DispStatusChangeNotification
        id={trackingId}
        trackingId={trackingId}
        vehicleId={vehicleId}
        onArrowClick={() => onTrackingToastButtonClick(trackingId)}
      />),
    });
  }

  useEffect(() => {
    if (bidRights.read) {
      apiFactory(BidsApi).apiBidsGetUnreadBidsGet()
        .then(result => dispatch(setUnreadBidsCount(result)));

      hubConnection.on(
        'ReceiveUnreadBids',
        (json) => {
          console.debug('received SendUnreadBids message');
          const notification = json as RealtimeBidDto;
          notification.bidIds.forEach(bidId => {
            dispatch(addUnreadBid(bidId));
          });
        }
      );

      hubConnection.on(
        'ReceiveBrokerReply',
        (json) => {
          console.debug('received ReceiveBrokerReply message');
          const notifications = json as RealtimeBidDto;
          notifications.bidIds.forEach(bidId => {
            dispatch(addUnreadBid(bidId));
            dispatch(markAsUnRead(bidId));
            dispatch(hasNewReplies(bidId));
            showBidNotification(bidId);
          });
        }
      );

      hubConnection.on(
        'ReceiveReadBid',
        (json) => {
          console.debug('received ReceiveReadBid message');
          const notifications = json as RealtimeBidDto;

          notifications.bidIds.forEach(bidId => {
            dispatch(removeUnreadBid());
            dispatch(markAsRead(bidId));
            dispatch(repliesViewed(bidId));
          });
        }
      );

      hubConnection.on(
        'ReceiveReadAllBids',
        () => {
          console.debug('received ReceiveReadAllBids message');
          dispatch(readAll());
          dispatch(setUnreadBidsCount(0));
        }
      )

      hubConnection.on(
        'ReceiveReloadBids',
        (unreadBidsCount: string) => {
          console.debug('received ReceiveReloadBids message');
          dispatch(setUnreadBidsCount(parseInt(unreadBidsCount)));
        }
      );
    }

    hubConnection.on(
      'ReceiveTrackingNotification',
      (trackingId: string, trackingNumber: string, status: ETrackingStatus) => {
        console.debug('received ReceiveTrackingNotification message');
        showTrackingNotification(parseInt(trackingId), trackingNumber, status);
      });

    hubConnection.on('ReceiveApprovedVehicles', (vehicleIds: number[]) => {
      console.debug('received ReceiveApprovedVehicles message');
      showApprovedVehicleNotifications(vehicleIds);
      dispatch(updateStoredNotificationsCount(x => x + 1));
    });

    hubConnection.on('ReceiveVehiclesExpiry', (data: VehicleExpiryDto[]) => {
      console.debug('received ReceiveVehiclesExpiry message');
      showVehicleExpiryNotifications(data);
    });

    // Add and remove non-anonymous handler when there are more than one handler.
    const onReceiveNewDriverBid = (json: any) => {
      const driverBid = json as DriverBid;
      console.debug('received ReceiveNewDriverBid message');
      if (driverBid?.loadedOrderId) {
        showNewDriverBidNotification(driverBid);
      }
    }
    hubConnection.on('ReceiveNewDriverBid', onReceiveNewDriverBid);


    hubConnection.on('ReceiveSignedDocument', (data: OwnerDocumentsSignedNotification) => {
      console.debug('received ReceiveSignedDocument message');
      showOwnerDocumentsSignedNotifications(data);
      dispatch(updateStoredNotificationsCount(x => x + 1));
    });
    hubConnection.on(
      'ReceiveDispStatusChangeNotification',
      (trackingId: number, vehicleId: number) => {
        showDispStatusChangeNotification(trackingId, vehicleId);
      }
    );

    return () => {
      hubConnection.off('ReceiveUnreadBids');
      hubConnection.off('ReceiveBrokerReply');
      hubConnection.off('ReceiveReadBid');
      hubConnection.off('ReceiveReloadBids');
      hubConnection.off('ReceiveReadAllBids');
      hubConnection.off('ReceiveTrackingNotification');
      hubConnection.off('ReceiveApprovedVehicles');
      hubConnection.off('ReceiveNewDriverBid', onReceiveNewDriverBid);
      hubConnection.off('ReceiveSignedDocument');
      hubConnection.off('ReceiveDispStatusChangeNotification');
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bidRights.read]);

  return (
    <>
      {bidEditDialogs}
      {trackingEditDialogs}
      {vehicleEditDialogs}
      {loadedOrderDialogs}
    </>
  );
};

export default PopupNotifications;