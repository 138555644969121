import React, { SetStateAction, useCallback, useEffect, useState } from 'react';
import Row from '../../../components/form/Row';
import Column from '../../../components/form/Column';
import Header from '../../../components/form/Header';
import Field from '../../../components/form/Field';
import StyledInputText from '../../../components/form/StyledInputText';
import { EBusinessType, Owner, Request, State } from '../../../generated-api';
import LogisticsPhoneInput from '../../../components/LogisticsPhoneInput';
import StyledInputMask from '../../../components/form/StyledInputMask';
import EditableDropdown from '../../../components/form/EditableDropdown';
import businessTypes from '../../owners/businessTypes';
import { RegistrationApi } from '../../../generated-api/apis/RegistrationApi';
import { showErrorToast } from '../../../components/LogisticsToast';
import { Button, Divider } from 'antd';
import { Checkbox } from 'primereact/checkbox';
import VisibilityToggler from '../../../components/VisibilityToggler';
import { FormikErrors, useFormik } from 'formik';
import ErrorBox from '../../../components/form/ErrorBox';
import { useParams } from 'react-router-dom';
import { useModal } from '../../../hooks';
import RegistrationConditions from './RegistrationConditions';
import { apiFactory } from '../../../shared';
import GoogleCityAutocomplete, { GoogleCityResult } from './GoogleCityAutocomplete';
import LLCType from '../../../data-moqs/LLCType';

export type StepProps<TStepResult> = {
  onStepComplete: (result: TStepResult) => any,
  initialValue?: TStepResult,
}

function Step1(props: StepProps<Request>) {
  const { referralId } = useParams();
  const [owner, _setOwner] = useState<Owner>({});
  const [states, setStates] = useState<State[]>([]);
  const [loading, setLoading] = useState(false);
  const conditionsModal = useModal(undefined);
  const setOwner = useCallback((action: SetStateAction<Owner>) => {
    _setOwner(action);
    validation.setValues(action);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  
  useEffect(() => {
    apiFactory(RegistrationApi).apiRegistrationStatesGet()
      .then(loadedStates => {
        setStates(loadedStates);
      }).catch(reason => {
        showErrorToast('Error on preloading information');
        console.error(reason);
    })
  }, []);

  const validation = useFormik<Owner>({
    initialValues: owner,
    validate: data => {
      const errors: FormikErrors<Owner> = {};

      const blankError = 'This field is required';
      const telephoneError = 'Please enter a valid telephone number.';
      const emailError = 'Please enter a valid email address';
      const requiredFields: Owner = {
        companyName: undefined,
        businessAs: undefined,
        employerId: undefined,
        businessType: undefined,
        zip: undefined,
        state: undefined,
        city: undefined,
        address: undefined,
        firstName: undefined,
        lastName: undefined,
        email: undefined,
      };
      if (data.businessType === EBusinessType.Other) {
        requiredFields.otherType = undefined as any;
      }

      Object.keys(requiredFields).forEach(field => {
        if (!(data as any)[field])
          (errors as any)[field] = blankError;
      });

      if (!data.phones || !data.phones[0]){
        errors.phones = blankError;
      } else if (data.phones[0].replace(/ /g, "").length < 11) {
        errors.phones = telephoneError;
      }

      if (data.email && !data.email.match(/^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/gm)) {
        errors.email = emailError;
      }

      return errors;
    },
    onSubmit: () => {},
  });

  const save = async () => {
    validation.handleSubmit();
    const errors = await validation.validateForm(owner);
    if (Object.keys(errors).length)
      return;

    try {
      setLoading(true);
      const request: Request = {
        owner: owner,
      }

      const response = await apiFactory(RegistrationApi).apiRegistrationStage1Post({
        request: request,
        userReferralId: referralId
      });
      props.onStepComplete(response);
    } catch (error: any) {
      showErrorToast('Error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const onZipChange = useCallback((value: GoogleCityResult) => {
    const state = states.find(x => x.name?.toLowerCase() === value.state?.toLowerCase());
    setOwner(old => {
      return {
        ...old,
        zip: value.zip,
        city: value.city,
        state: state,
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [states]);

  useEffect(() => {
    if (owner.businessType !== EBusinessType.Llc) {
      setOwner(old => {
        return { ...old, llc: undefined };
      })
    }
  }, [owner.businessType, setOwner])

  return <>
    <RegistrationConditions
      data={undefined as never}
      loading={false}
      visible={conditionsModal.visible}
      hide={conditionsModal.hide}
    />
    <Row>
      <Column>
        <i className='pi pi-info-circle mr-1 font-bold' />
        <span className='font-bold'>Registration conditions, </span>
        <Button
          type='link'
          className='p-0'
          onClick={e => {
            e.preventDefault();
            conditionsModal.show(undefined);
          }}
        >
          click here!
        </Button>
      </Column>
    </Row>
    <Row>
      <Column className='col-12'>
        <Header>Company info</Header>
      </Column>
    </Row>
    <Row>
      <Column className='col-6'>
        <Field label='Company name' required>
          <StyledInputText
            // @ts-ignore
            value={owner.companyName}
            placeholder='Company name'
            onChange={e => {
              setOwner(owner => {
                return { ...owner, companyName: e.target.value };
              })
            }}
          />
        </Field>
        <ErrorBox>{validation.errors.companyName}</ErrorBox>
      </Column>
      <Column className='col-6'>
        <Field label='Phone number' required>
          <LogisticsPhoneInput
            value={getPhone(owner)}
            country={'us'}
            onChange={number => setOwner(old => {
              return { ...old, phones: setPhone(old, number) };
            })}
          />
        </Field>
        <ErrorBox>{validation.errors.phones}</ErrorBox>
      </Column>
    </Row>
    <Row>
      <Column className='col-6'>
        <Field label='Doing business as' required>
          <StyledInputText
            // @ts-ignore
            value={owner.businessAs}
            placeholder='Doing business as'
            onChange={e => {
              setOwner(owner => {
                return { ...owner, businessAs: e.target.value };
              })
            }}
          />
        </Field>
        <ErrorBox>{validation.errors.businessAs}</ErrorBox>
      </Column>
      <Column className='col-6'>
        <Field label='Employer ID number' required>
          <StyledInputMask
            // @ts-ignore
            value={owner.employerId}
            mask='99-9999999'
            placeholder='__-_______'
            onChange={e => setOwner(old => {
              return { ...old, employerId: e.target.value };
            })}
          />
        </Field>
        <ErrorBox>{validation.errors.employerId}</ErrorBox>
      </Column>
    </Row>
    <Row>
      <Column className={owner.businessType === EBusinessType.Llc ? 'col-3' : 'col-6'}>
        <Field label='Business type' required>
          <EditableDropdown
            options={businessTypes}
            optionLabel='name'
            renderOption={x => x.name}
            renderSelected={x => x?.name || ''}
            renderEmptySelected={() => 'Chose only one of the following seven items.'}
            value={businessTypes.find(x => x.id === owner.businessType)}
            onChange={newValue => setOwner(old => {
              return { ...old, businessType: newValue.id };
            })}
          />
        </Field>
        <ErrorBox>{validation.errors.businessType}</ErrorBox>
      </Column>
      <VisibilityToggler visible={owner.businessType === EBusinessType.Llc}>
        <Column className='col-3'>
          <Field label='Business type' required>
            <EditableDropdown
              options={LLCType}
              optionLabel='name'
              renderOption={x => x.name}
              renderSelected={x => x?.name || ''}
              renderEmptySelected={() => '-'}
              value={LLCType.find(x => x.id === owner.llc)}
              onChange={newValue => setOwner(old => {
                return { ...old, llc: newValue.id };
              })}
            />
          </Field>
          <ErrorBox>{validation.errors.llc}</ErrorBox>
        </Column>
      </VisibilityToggler>
      <Column className='col-3'>
        <Field label='ZIP code' required>
          <GoogleCityAutocomplete
            textValue={owner.zip}
            onValueSelected={onZipChange}
            onInputChange={value => setOwner(old => {
              return { ...old, zip: value };
            })}
          />
        </Field>
        <ErrorBox>{validation.errors.zip}</ErrorBox>
      </Column>
      <Column className='col-3'>
        <Field label='State' required>
          <EditableDropdown
            options={states}
            optionLabel='name'
            renderOption={state => state.name}
            renderSelected={state => state?.name || ''}
            renderEmptyOption={() => 'State'}
            renderEmptySelected={() => 'State'}
            value={owner.state}
            filter
            onChange={newValue => setOwner(old => {
              return { ...old, state: newValue };
            })}
          />
        </Field>
        <ErrorBox>{validation.errors.state}</ErrorBox>
      </Column>
    </Row>
    <VisibilityToggler visible={owner.businessType === EBusinessType.Other}>
      <Row>
        <Column className='col-4'>
          <Field label='Other' required>
            <StyledInputText
              // @ts-ignore
              value={owner.otherType}
              placeholder='Other'
              onChange={e => {
                setOwner(owner => {
                  return { ...owner, otherType: e.target.value };
                })
              }}
            />
          </Field>
          <ErrorBox>{validation.errors.businessAs}</ErrorBox>
        </Column>
      </Row>
    </VisibilityToggler>
    <Row>
      <Column className='col-6'>
        <Field label='City' required>
          <StyledInputText
            // @ts-ignore
            value={owner.city}
            placeholder='City'
            onChange={e => setOwner(old => {
              return { ...old, city: e.target.value };
            })}
          />
        </Field>
        <ErrorBox>{validation.errors.city}</ErrorBox>
      </Column>
      <Column className='col-6'>
        <Field label='Address' required>
          <StyledInputText
            // @ts-ignore
            value={owner.address}
            placeholder='Address'
            onChange={e => setOwner(old => {
              return { ...old, address: e.target.value };
            })}
          />
        </Field>
        <ErrorBox>{validation.errors.address}</ErrorBox>
      </Column>
    </Row>
    <Divider />
    <Row>
      <Column className='col-3'>
        <Field label='First name' required>
          <StyledInputText
            // @ts-ignore
            value={owner.firstName}
            placeholder='First name'
            onChange={e => setOwner(old => {
              return { ...old, firstName: e.target.value };
            })}
          />
        </Field>
        <ErrorBox>{validation.errors.firstName}</ErrorBox>
      </Column>
      <Column className='col-3'>
        <Field label='Last name' required>
          <StyledInputText
            // @ts-ignore
            value={owner.lastName}
            placeholder='Last name'
            onChange={e => setOwner(old => {
              return { ...old, lastName: e.target.value };
            })}
          />
        </Field>
        <ErrorBox>{validation.errors.lastName}</ErrorBox>
      </Column>
      <Column className='col-6'>
        <Field label='Email' required>
          <StyledInputText
            // @ts-ignore
            value={owner.email}
            placeholder='youremail@service.com'
            onChange={e => setOwner(old => {
              return { ...old, email: e.target.value };
            })}
          />
        </Field>
        <ErrorBox>{validation.errors.email}</ErrorBox>
      </Column>
    </Row>
    <Row>
      <Column className='col-3'>
        <div className='field-checkbox'>
          <label>Tax exempt?</label>
          <Checkbox
            checked={owner.taxExempt}
            onChange={e => {
              setOwner(old => {
                return { ...old, taxExempt: e.checked };
              });
            }}
          />
        </div>
      </Column>
    </Row>
    <VisibilityToggler visible={owner.taxExempt}>
      <Divider />
      <Row>
        <Column>
          <div className='text-xl'>Exemptions</div>
        </Column>
      </Row>
      <Row>
        <Column className='col-6'>
          <div>(codes apply only to certain entities, not individuals;</div>
          <div className='mb-3'>see instructions on page 3)</div>
        </Column>
      </Row>
      <Row>
        <Column className='col-6'>
          <Field label='Exempt payee code (if any)'>
            <StyledInputText
              // @ts-ignore
              value={owner.exemptPayeeCode}
              placeholder='Code'
              onChange={e => setOwner(old => {
                return { ...old, exemptPayeeCode: e.target.value };
              })}
            />
          </Field>
        </Column>
        <Column className='col-6'>
          <Field label='Exemption from FATCA reporting code (if any)'>
            <StyledInputText
              // @ts-ignore
              value={owner.fatca}
              placeholder='Code'
              onChange={e => setOwner(old => {
                return { ...old, fatca: e.target.value };
              })}
            />
          </Field>
        </Column>
      </Row>
    </VisibilityToggler>
    <Row>
      <Column className='col-12'>
        <Button
          type="primary"
          loading={loading}
          onClick={save}
          className='w-full'
        >
          Next
        </Button>
      </Column>
    </Row>
  </>
}

const getPhone = (owner: Owner) =>
  owner.phones?.length ? owner.phones[0] : undefined;

const setPhone = (owner: Owner, phone: string) => {
  const phones = owner.phones?.length ? [...owner.phones] : [];
  phones[0] = phone;
  return phones;
}

export default Step1;