/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ECompany = {
    None: 'None',
    Zubr: 'Zubr',
    Oliver: 'Oliver',
    Brz: 'BRZ',
    ZubrFreight: 'ZubrFreight'
} as const;
export type ECompany = typeof ECompany[keyof typeof ECompany];


export function ECompanyFromJSON(json: any): ECompany {
    return ECompanyFromJSONTyped(json, false);
}

export function ECompanyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ECompany {
    return json as ECompany;
}

export function ECompanyToJSON(value?: ECompany | null): any {
    return value as any;
}

