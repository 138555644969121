import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector, useModal } from '../../hooks';
import {
  ListOfLoads,
  ListOfLoadsApi,
  Brokerage,
  Vehicle,
  Owner,
  Driver,
  User, EDriverOrder,
} from '../../generated-api';
import { apiFactory } from '../../shared';
import LogisticsDialog from '../../components/LogisticsDialog';
import CenteredSpinner from '../../components/CenteredSpinner';
import { ModalParameters } from '../../shared';
import Field from '../../components/form/Field';
import StyledInputText from '../../components/form/StyledInputText';
import StyledInputNumber from '../../components/form/StyledInputNumber';
import EditableDropdown from '../../components/form/EditableDropdown';
import LogisticsCalendar from '../../components/form/datetime/LogisticsCalendar';
import LogisticsWeekCalendar from '../../components/form/datetime/LogisticsWeekCalendar';
import { logisticsConfirmDialog } from '../../components/LogisticsConfifmDialog';
import { showSuccessToast, showToast } from '../../components/LogisticsToast';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { classNames } from 'primereact/utils';
import { updateEntity } from './ListOfLoadsSlice';
import { useFormik } from 'formik';
import { DatePickerProps, Typography } from 'antd';
import StyledInputMask from '../../components/form/StyledInputMask';
import useRights from '../../hooks/RightsHook';
import LsNumber, { lsNumberToString, stringToLsNumber } from './LsNumber';
import { getSystemSetting } from '../settings/SettingsSlice';
import dayjs from 'dayjs';
import BrokerageEdit from '../brokerage/BrokerageEdit';
import ListOfLoadsEditCaptions from './ListOfLoadsEditCaptions';
import LogisticsFilesUpload from '../../components/form/files/upload/LogisticsFilesUpload';
import useFilePreview from '../../components/form/files/preview/filePreviewWidget';
import LogisticsFilePreview from '../../components/form/files/preview/LogisticsFilePreview';
import TrackingEdit from '../tracking/TrackingEdit';
import { Panel } from 'primereact/panel';

const { Link } = Typography;

export interface ListOfLoadsEditParameters extends ModalParameters<ListOfLoads> {

}

interface ListOfLoadsEditFormErrors {
  r?: string;
  createtime?: string;
  unit?: string;
  driver?: string;
  company?: string;
  brokerage?: string;
  pickUpAtCity?: string;
  pickUpDate?: string;
  deliverToCity?: string;
  deliveryDate?: string;
  miles?: string;
  gross?: string;
  priceForDriver?: string;
  user?: string;
}

type LinkedDropdownTypes = {
  driver: Driver | null | undefined,
  unit: Vehicle | null | undefined,
  company: Owner | null | undefined,
}

type LinkedDropdownType = keyof LinkedDropdownTypes;

function ListOfLoadsEdit(props: ListOfLoadsEditParameters) {
  const [listOfLoads, setListOfLoads] = useState<ListOfLoads>(props?.data);
  const [brokerages, setBrokerages] = useState<Brokerage[]>([]);
  const [allVehicles, setAllVehicles] = useState<Vehicle[]>([]);
  const [allDrivers, setAllDrivers] = useState<Driver[]>([]);
  const [allCompanies, setAllCompanies] = useState<Owner[]>([]);
  const [allUsers, setAllUsers] = useState<User[]>([]);
  const [dropdownVehicles, setDropdownVehicles] = useState<Vehicle[]>([]);
  const [dropdownDrivers, setDropdownDrivers] = useState<Driver[]>([]);
  const [dropdownCompanies, setDropdownCompanies] = useState<Owner[]>([]);
  const [states, setStates] = useState<(string | null | undefined)[]>([]);
  const brokerageModal = useModal<Brokerage>({});
  const trackingModal = useModal<number | undefined>(undefined)
  const [stateLoading, setLoading] = useState(props?.loading);
  const systemSettings = useAppSelector(state => state.settings.system);
  const dispatch = useAppDispatch();
  const rights = useRights(security => security.listOfLoads);
  const filePreviewWidget = useFilePreview();
  const readonly = useMemo(
    () => (props.data?.id && !rights.update)
      || (!props.data?.id && !rights.create)
      || listOfLoads.factoring
      || false,
    [listOfLoads.factoring, props.data?.id, rights]);

  let selectedVehicle: Vehicle = {};
  const setSelectedVehicle = (value : Vehicle) => {
    selectedVehicle = value;
  };

  let selectedDriver: Driver = {};
  const setSelectedDriver = (value : Driver) => {
    selectedDriver = value;
  };

  let selectedOwner: Owner = {};
  const setSelectedOwner = (value : Owner) => {
    selectedOwner = value;
  };

  const onWeekChange: DatePickerProps['onChange'] = (newWeek) => {
    // Remove event listeners for week clicking events
    document.querySelectorAll<HTMLElement>('.ant-picker-cell-week').forEach((e) => {
      e.removeEventListener("click", handleCalendarClick);
    });

    if (!newWeek) return;

    const initialWeek = stringToLsNumber(listOfLoads.number).week;
    const newNumber = newWeek.diff(initialWeek, 'week') || newWeek.diff(initialWeek, 'year')
      ? { ...formik.values.lsNumber, week: newWeek, consecutiveNumber: 0 } as LsNumber
      : stringToLsNumber(listOfLoads.number);

    formik.setValues(previousValue => {
      return { ...previousValue, lsNumber: newNumber };
    });
  }

  const handleCalendarClick = (e: MouseEvent) => {
    // @ts-ignore
    e.target!.nextSibling!.click();
  };

  const setDropdownStatesValues = (loadedListOfLoads : ListOfLoads, vehicles : Vehicle[], drivers : Driver[], companies : Owner[]) => {
    const filteredVehicle = vehicles.find(v => v.id === loadedListOfLoads.vehicleId);
    handleVehicleChange(filteredVehicle!);

    const filteredDriver = drivers.find(driver =>
      driver.firstName === loadedListOfLoads.dFirstName
      && driver.lastName === loadedListOfLoads.dLastName
      && (filteredVehicle ? driver.vehicleId === filteredVehicle.id : true));
    handleDriversChange(filteredDriver!);

    const filteredCompany = companies.find(c =>
      c.companyName === loadedListOfLoads.companyName
      && (filteredVehicle ? filteredVehicle.ownerId === c.id : true));
    handleOwnerChange(filteredCompany!);
  }

  useEffect(() => {
    if (!props?.visible) return;
    setLoading(true);
    const lolApi = apiFactory(ListOfLoadsApi);
    const listOfLoadsPromise = props.data.id
      ? lolApi.apiListOfLoadsIdGet({ id: props.data.id })
      : Promise.resolve(listOfLoads);
    const settingsPromise = systemSettings.abbreviation
      ? Promise.resolve()
      : dispatch(getSystemSetting({ id: 1}));

    Promise.all([
      listOfLoadsPromise,
      lolApi.apiListOfLoadsAllStatesGet(),
      lolApi.apiListOfLoadsAllBrokeragesGet(),
      lolApi.apiListOfLoadsAllDriversGet(),
      lolApi.apiListOfLoadsAllVehiclesGet(),
      lolApi.apiListOfLoadsAllOwnersGet(),
      lolApi.apiListOfLoadsAllUsersGet(),
      settingsPromise,
    ]).then(([
      loadedListOfLoads,
      loadedStates,
      loadedBrokerages,
      loadedDrivers,
      loadedVehicles,
      loadedOwners,
      loadedUsers,
    ]) => {
      setListOfLoads(loadedListOfLoads);
      setDropdownStatesValues(loadedListOfLoads, loadedVehicles, loadedDrivers, loadedOwners);
      setStates(loadedStates.map(s => s.code !== null ? s.code : undefined));
      setBrokerages(loadedBrokerages || []);
      setAllDrivers(loadedDrivers.filter(d => d.firstName && d.lastName));
      setDropdownDrivers(loadedDrivers.filter(d => d.firstName && d.lastName));
      setAllVehicles(loadedVehicles);
      setDropdownVehicles(loadedVehicles);
      setAllCompanies(loadedOwners);
      setDropdownCompanies(loadedOwners);
      setAllUsers(loadedUsers as User[]);
      trackingModal.setData(loadedListOfLoads.trackingId || 0);

      const formikValues= mapLolToFormik(loadedListOfLoads);
      const formikValuesWithMasters = {
        ...formikValues,
        driver: selectedDriver,
        company: selectedOwner,
        unit: selectedVehicle,
      };
      formik.resetForm({ values: formikValuesWithMasters, touched: {}});
    }).catch(() => showToast(
      { severity: 'error', summary: 'Error', detail: 'Something went wrong with preloading information on the form'}))
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mapLolToFormik = (lol: ListOfLoads) => {
    const formikValues = {
      r: lol.r,
      createtime: lol.createtime,
      driver: null as any as Driver,
      company: null as any as Owner,
      unit: null as any as Vehicle,
      user: lol.user,
      brokerage: lol.brokerage,
      pickUpAtCity: lol.pickUpAtCity,
      pickUpDate: lol.pickUpDate,
      deliverToCity: lol.deliverToCity,
      deliveryDate: lol.deliveryDate,
      miles: lol.miles,
      gross: lol.gross,
      priceForDriver: lol.priceForDriver,
      lsNumber: lol.number
        ? stringToLsNumber(lol.number)
        : { abbreviation: systemSettings.abbreviation, week: dayjs().tz(), consecutiveNumber: 0 } as LsNumber,
      userFullName: lol.userFullName || '',
      pickUpAtCompany: lol.pickUpAtCompany,
      deliveryCompany: lol.deliveryCompany,
      pieces: lol.pieces,
      boxDims: lol.boxDims,
      weight: lol.weight,
    };

    return formikValues;
  }

  const formik = useFormik({
    initialValues: mapLolToFormik(listOfLoads),
    validate: (data) => {
      const errors: ListOfLoadsEditFormErrors = {};
      const blankError = 'This value should not be blank.';
      const blankOrZeroError = 'This value should not be blank or 0.'
      const dateFormatError = 'This value should be properly entered.';
      const fromDateShouldComeBeforeToDate = 'Pick-up date should come before delivery date';

      if (!data.r) {
        errors.r = blankError;
      }
      if (!data.createtime) {
        errors.createtime = dateFormatError;
      }
      if (!data.unit) {
        errors.unit = blankOrZeroError;
      }
      if (!data.driver) {
        errors.driver = blankError;
      }
      if (!data.company) {
        errors.company = blankError;
      }
      if (!data.brokerage) {
        errors.brokerage = blankError;
      }
      if (!data.pickUpAtCity) {
        errors.pickUpAtCity = blankError;
      }
      if (!data.pickUpDate) {
        errors.pickUpDate = dateFormatError;
      }
      if (!data.deliverToCity) {
        errors.deliverToCity = blankError;
      }
      if (!data.deliveryDate) {
        errors.deliveryDate = dateFormatError;
      }
      if (data.deliveryDate && data.pickUpDate
        && data.deliveryDate <= data.pickUpDate) {
        errors.deliveryDate = fromDateShouldComeBeforeToDate;
        errors.pickUpDate = fromDateShouldComeBeforeToDate;
      }
      if (!data.miles && data.miles !== 0) {
        errors.miles = blankError;
      }
      if (!data.gross && data.gross !== 0) {
        errors.gross = blankError;
      }
      if (!data.priceForDriver && data.priceForDriver !== 0) {
        errors.priceForDriver = blankError;
      }
      if (!data.user) {
        errors.user = blankError;
      }
      return errors;
    },
    onSubmit: () => {}
  });

  // To prevent annoying TS warning, making this assignment.
  // See https://stackoverflow.com/questions/72039345/typescript-type-of-input-parameter-formik-error-and-formik-touched-prime-react
  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;

  const isFormFieldValid = (name: string) => !!(formikTouched[name] && formikErrors[name]);
  const getFormErrorMessage = (name: string) => {
    return isFormFieldValid(name) && <small className="p-error">{formikErrors[name]}</small>;
  };

  const calculateProfitAndPercent = (
    gross: number | undefined | null,
    priceForDriver: number | undefined | null
  ): number[] => {
    const profit = (gross || 0) - (priceForDriver || 0);
    const profitPercent = profit > 0 ? Math.round((profit / (gross || 0)) * 100) : 0;
    return [ profit, profitPercent ];
  }

  const hide = useCallback((value: ListOfLoads | undefined) => {
    if (value) {
      dispatch(updateEntity(value));
    }

    props.hide(value);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnHide = useCallback(() => {
    if (formik.dirty) {
      logisticsConfirmDialog({
        message: 'Save changes?',
        closable: false,
        accept() {
          onSubmit();
        },
        reject() {
          hide(undefined);
        }
      });
    } else {
      hide(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.hide, listOfLoads, formik]);

  const validate = useCallback(async () => {
    formik.handleSubmit();
    const errors = await formik.validateForm(formik.values);
    if (Object.keys(errors).length) {
      throw new Error('Fix errors and try again');
    }
  }, [formik]);

  const onSubmit = async () => {
    try {
      await validate();
      setLoading(true);

      const number = formik.touched.lsNumber || !listOfLoads.id
        ? { ...formik.values.lsNumber, consecutiveNumber: 0 }
        : formik.values.lsNumber;

      // Calculating profit and profitPercent based on form's "Gross" and "Driver price".
      const [ profit, profitPercent ] = calculateProfitAndPercent(listOfLoads?.gross, listOfLoads?.priceForDriver);

      // As we got the latest number from backend and as it's available to use, we setting it to the LoL object.
      const listOfLoadsForSave = {
        ...listOfLoads,
        profit,
        profitPercent,
        number: lsNumberToString(number),
      }
      setListOfLoads(listOfLoadsForSave)

      const response = listOfLoads.id
      ? await apiFactory(ListOfLoadsApi)
        .apiListOfLoadsIdPut({ id: listOfLoads.id!, listOfLoads: listOfLoadsForSave })
      : await apiFactory(ListOfLoadsApi)
        .apiListOfLoadsPost({ listOfLoads: listOfLoadsForSave });

      showSuccessToast('Load was saved');

      formik.resetForm({ values: { ...formik.values }, touched: {} });
      hide(response || listOfLoadsForSave);
    } catch (error: any) {
      showToast({ severity: 'error', summary: 'Error', detail: error.toString() });
      throw error;
    } finally {
      setLoading(false);
    }
  }

  const handleDriversChange = (newValue: Driver) => {
    formik.setFieldValue('driver', newValue, true);
    setSelectedDriver(newValue);
    setListOfLoads(lol => { return {...lol, dFirstName: newValue?.firstName || '', dLastName: newValue?.lastName || ''} });
  }

  const handleOwnerChange = (newValue: Owner) => {
    formik.setFieldValue('company', newValue, true);
    setSelectedOwner(newValue);
    setListOfLoads(lol => { return {...lol, companyName: newValue?.companyName || ''} });
  }

  const handleVehicleChange = (newValue: Vehicle) => {
    formik.setFieldValue('unit', newValue, true);
    setSelectedVehicle(newValue);
    setListOfLoads(lol => {
      return { ...lol, vehicleId: newValue?.id }
    });
  }

  const handleLinkedDropdownChange = <Type extends LinkedDropdownType>(newValue: LinkedDropdownTypes[Type], type: Type) => {
    if (!type) return;

    if (newValue) autofillLinkedDropdowns(newValue!, type);
    else releaseFilteredDropdown(type);
  }

  const autofillLinkedDropdowns = <Type extends LinkedDropdownType>(
    newValue: NonNullable<LinkedDropdownTypes[Type]>,
    type: Type
  ) => {
    let filteredCompanies: Owner[] | null = null;
    let filteredUnits: Vehicle[] | null = null;
    let filteredDrivers: Driver[] | null = null;

    let autofilledCompany: Owner | null | undefined = formik.values.company;
    let autofilledUnit: Vehicle | null | undefined = formik.values.unit;
    let autofilledDriver: Driver | null | undefined = formik.values.driver;

    switch (type) {
      case 'driver':
        autofilledDriver = newValue;
        autofilledUnit = allVehicles.find(unit => unit.id === autofilledDriver?.vehicleId);
        autofilledCompany = autofilledUnit ? allCompanies.find(owner => owner.id === autofilledUnit?.ownerId) : null;
        filteredCompanies = autofilledCompany ? [autofilledCompany] : [];
        filteredUnits = autofilledUnit ? [autofilledUnit] : [];
        break;

      case 'company':
        autofilledCompany = newValue;
        filteredUnits = allVehicles.filter(vehicle => vehicle.ownerId === autofilledCompany?.id);
        filteredDrivers = allDrivers.filter(driver => filteredUnits!.find(unit => driver.vehicleId === unit.id));
        autofilledUnit = filteredUnits.find(unit => unit);
        autofilledDriver = filteredDrivers.find(driver =>
          driver.vehicleId === autofilledUnit?.id &&
          driver.order === EDriverOrder.First);
        if (!autofilledDriver) {
          autofilledDriver = filteredDrivers.find(driver => driver.vehicleId === autofilledUnit?.id)
        }
        break;

      case 'unit':
        autofilledUnit = newValue;
        autofilledCompany = allCompanies.find(owner => owner.id === autofilledUnit?.ownerId);
        filteredCompanies = autofilledCompany ? [autofilledCompany] : [];
        filteredDrivers = allDrivers.filter(driver => driver.vehicleId === newValue.id);
        autofilledDriver = filteredDrivers.find(driver => driver.order === EDriverOrder.First);
        if (!autofilledDriver) {
          autofilledDriver = filteredDrivers.find(driver => driver);
        }
        break;
    }

    handleDriversChange(autofilledDriver!);
    setDropdownDrivers(filteredDrivers || allDrivers);

    handleOwnerChange(autofilledCompany!);
    setDropdownCompanies(filteredCompanies || allCompanies);

    handleVehicleChange(autofilledUnit!);
    setDropdownVehicles(filteredUnits || allVehicles);
  }

  const releaseFilteredDropdown = (type: LinkedDropdownType) => {
    switch (type) {
      case 'driver':
        handleDriversChange(undefined!);
        setDropdownDrivers(allDrivers);
        break;

      case 'company':
        handleOwnerChange(undefined!);
        setDropdownCompanies(allCompanies);
        break;

      case 'unit':
        handleVehicleChange(undefined!);
        setDropdownVehicles(allVehicles);
        break;
    }
  }

  const brokerageEdit = brokerageModal.visible
  ? <BrokerageEdit
    data={brokerageModal.data}
    loading={false}
    visible={brokerageModal.visible}
    hide={editedBrokerage => {
      if (!editedBrokerage) {
        brokerageModal.hide();
        return;
      }
      formik.setFieldValue('brokerage', editedBrokerage);
      setListOfLoads(lol => {
        return { ...lol, brokerage: editedBrokerage };
      });
      const brokerageIsNew = !brokerages.find(x => x.id === editedBrokerage.id);
      setBrokerages(oldBrokerages => {
        return brokerageIsNew
          ? [editedBrokerage , ...oldBrokerages]
          : [...oldBrokerages].map(oldBrokerage => {
            return oldBrokerage.id === editedBrokerage.id
              ? editedBrokerage
              : oldBrokerage;
          });
      });
      brokerageModal.hide();
    }}
  />
  : <></>;

  const trackingEdit = useMemo(() => {
    if (!trackingModal.data) {
      return <></>;
    }

    return <TrackingEdit
      loading={false}
      visible={trackingModal.visible}
      hide={trackingModal.hide}
      data={{id: trackingModal.data}}
      key={trackingModal.data}
    />
  }, [trackingModal.visible, trackingModal.hide, trackingModal.data]);

  return (
    <>
      {brokerageEdit}
      {trackingEdit}
      <LogisticsDialog
        visible={props?.visible}
        onHide={handleOnHide}
        dismissableMask
        className='logistics-dialog-edit-form logistics-dialog-edit-form_loadable w-8'
      >
        <header className='text-2xl w-full flex-wrap mb-5'>
          <span className='font-bold'>Load - {lsNumberToString(formik.values.lsNumber)}&nbsp;</span>
          {listOfLoads.trackingId && (
            <Link underline className='text-lg ml-4' onClick={() => trackingModal.show(listOfLoads.trackingId || 0)}>
              Tracking
            </Link>
          )}
        </header>
        <main className='logistics-dialog-edit-form__content'>
          <div className='grid'>
            <div className='col'>
              <Field label={ListOfLoadsEditCaptions.Syl}>
                <StyledInputMask
                  // @ts-ignore
                  value={listOfLoads.syl}
                  mask='?99999999999999999999999'
                  slotChar=''
                  onChange={e => setListOfLoads(lol => {
                    return { ...lol, syl: e.value?.toString() || '0' };
                  })}
                  disabled={readonly}
                />
              </Field>
            </div>
            <div className='col'>
              <Field label={ListOfLoadsEditCaptions.R} required>
                <StyledInputText
                  // @ts-ignore
                  value={formik.values.r}
                  onChange={e => {
                    formik.setFieldValue('r', e.target.value, true);
                    setListOfLoads(lol => {
                      return { ...lol, r: e.target.value };
                    });
                  }}
                  className={classNames({ 'p-invalid': isFormFieldValid('r') })}
                  disabled={readonly}
                />
                {getFormErrorMessage('r')}
              </Field>
            </div>
            <div className='col'>
              <Field label={ListOfLoadsEditCaptions.Createtime} required>
                <LogisticsCalendar
                  value={formik.values.createtime}
                  onChange={newValue => {
                    setListOfLoads(lol => {
                      return { ...lol, createtime: newValue };
                    });
                    const formikValue = newValue || null;
                    formik.setFieldValue('createtime', formikValue, true);
                  }}
                  disabled={readonly}
                  isValidated={isFormFieldValid('createtime')}
                />
                {getFormErrorMessage('createtime')}
              </Field>
            </div>
            <div className='col'>
              <Field label={ListOfLoadsEditCaptions.VehicleId} required>
                <EditableDropdown
                  options={dropdownVehicles}
                  optionLabel='id'
                  renderOption={vehicle => vehicle.id}
                  renderSelected={vehicle => vehicle?.id}
                  renderEmptySelected={() => '-'}
                  renderEmptyOption={() => '-'}
                  value={formik.values.unit}
                  onChange={v => handleLinkedDropdownChange(v, 'unit')}
                  disabled={readonly}
                  className={classNames({ 'p-invalid': isFormFieldValid('unit') })}
                  filter
                />
                {getFormErrorMessage('unit')}
              </Field>
            </div>
          </div>
          <div className='grid'>
            <div className='col'>
              <Field label={ListOfLoadsEditCaptions.Driver} required>
                <EditableDropdown
                  options={dropdownDrivers}
                  optionLabel='name'
                  renderOption={getDriversFullName}
                  renderSelected={getDriversFullName}
                  renderEmptySelected={() => '-'}
                  renderEmptyOption={() => '-'}
                  value={formik.values.driver}
                  onChange={d => handleLinkedDropdownChange(d, 'driver')}
                  disabled={readonly}
                  className={classNames({ 'p-invalid': isFormFieldValid('driver') })}
                  filter
                />
                {getFormErrorMessage('driver')}
              </Field>
            </div>
            <div className='col'>
              <Field label={ListOfLoadsEditCaptions.CompanyName} required>
                <EditableDropdown
                  options={dropdownCompanies}
                  optionLabel='name'
                  renderOption={company => company.companyName}
                  renderSelected={company => company?.companyName}
                  renderEmptySelected={() => '-'}
                  renderEmptyOption={() => '-'}
                  value={formik.values.company}
                  onChange={c => handleLinkedDropdownChange(c, 'company')}
                  disabled={readonly}
                  className={classNames({ 'p-invalid': isFormFieldValid('company') })}
                  filter
                />
                {getFormErrorMessage('company')}
              </Field>
            </div>
            <div className='col'>
              <Field label={ListOfLoadsEditCaptions.Brokerage} required>
                <EditableDropdown
                  options={brokerages}
                  optionLabel='name'
                  renderOption={brokerage => brokerage.name}
                  renderSelected={brokerage => brokerage?.name}
                  renderEmptySelected={() => '-'}
                  value={formik.values.brokerage}
                  onChange={newValue => {
                    formik.setFieldValue('brokerage', newValue, true);
                    setListOfLoads(lol => {
                      return { ...lol, brokerage: newValue };
                    });
                  }}
                  disabled={readonly}
                  openEditing={value => brokerageModal.show(value)}
                  openAdding={() => brokerageModal.show({})}
                  hideEditAndCreate={!rights.brokerageEditing}
                  className={classNames({ 'p-invalid': isFormFieldValid('brokerage') })}
                  filter
                />
                {getFormErrorMessage('brokerage')}
              </Field>
            </div>
          </div>
          <Panel header='Pick up' className='w-full'>
            <div className='grid'>
              <div className='col'>
                <Field label={'Company'}>
                  <StyledInputText
                    // @ts-ignore
                    value={formik.values.pickUpAtCompany}
                    onChange={e => {
                      formik.setFieldValue('pickUpAtCompany', e.target.value, true);
                      setListOfLoads(lol => {
                        return { ...lol, pickUpAtCompany: e.target.value };
                      });
                    }}
                    disabled={readonly}
                  />
                </Field>
              </div>
              <div className='col'>
                <Field label={ListOfLoadsEditCaptions.PickUpDate} required>
                  <LogisticsCalendar
                    value={formik.values.pickUpDate}
                    showTime
                    showSetToNowButton
                    onChange={newValue => {
                      setListOfLoads(lol => {
                        return { ...lol, pickUpDate: newValue };
                      });
                      if (newValue === undefined) {
                        return formik.setFieldValue('pickUpDate', null, true);
                      }
                      formik.setFieldValue('pickUpDate', newValue, true);
                    }}
                    disabled={readonly}
                    isValidated={isFormFieldValid('pickUpDate')}
                  />
                  {getFormErrorMessage('pickUpDate')}
                </Field>
              </div>
            </div>
            <div className='grid'>
              <div className='col'>
                <Field label={ListOfLoadsEditCaptions.PickUpAtCity} required>
                  <StyledInputText
                    // @ts-ignore
                    value={formik.values.pickUpAtCity}
                    onChange={e => {
                      formik.setFieldValue('pickUpAtCity', e.target.value, true);
                      setListOfLoads(lol => {
                        return { ...lol, pickUpAtCity: e.target.value };
                      });
                    }}
                    disabled={readonly}
                    className={classNames({ 'p-invalid': isFormFieldValid('pickUpAtCity') })}
                  />
                  {getFormErrorMessage('pickUpAtCity')}
                </Field>
              </div>
              <div className='col'>
                <Field label={ListOfLoadsEditCaptions.PickUpAtState} required>
                  <EditableDropdown
                    options={states}
                    optionLabel='name'
                    renderOption={s => s}
                    renderSelected={s => s}
                    renderEmptySelected={() => '-'}
                    value={listOfLoads.pickUpAtState}
                    // @ts-ignore
                    onChange={newValue => setListOfLoads(lol => {
                      return { ...lol, pickUpAtState: newValue };
                    })}
                    disabled={readonly}
                    filter
                  />
                </Field>
              </div>
            </div>
          </Panel>
          <Panel header='Delivery' className='w-full mt-3'>
            <div className='grid'>
              <div className='col'>
                <Field label={'Company'}>
                  <StyledInputText
                    // @ts-ignore
                    value={formik.values.deliveryCompany}
                    onChange={e => {
                      formik.setFieldValue('deliveryCompany', e.target.value, true);
                      setListOfLoads(lol => {
                        return { ...lol, deliveryCompany: e.target.value };
                      });
                    }}
                    disabled={readonly}
                    className={classNames({ 'p-invalid': isFormFieldValid('deliveryCompany') })}
                  />
                  {getFormErrorMessage('deliveryCompany')}
                </Field>
              </div>
              <div className='col'>
                <Field label={ListOfLoadsEditCaptions.DeliveryDate} required>
                  <LogisticsCalendar
                    value={formik.values.deliveryDate}
                    showTime
                    showSetToNowButton
                    onChange={newValue => {
                      setListOfLoads(lol => {
                        return { ...lol, deliveryDate: newValue };
                      });
                      if (newValue === undefined) {
                        return formik.setFieldValue('deliveryDate', null, true);
                      }
                      formik.setFieldValue('deliveryDate', newValue, true);
                    }}
                    disabled={readonly}
                    isValidated={isFormFieldValid('deliveryDate')}
                  />
                  {getFormErrorMessage('deliveryDate')}
                </Field>
              </div>
            </div>
            <div className='grid'>
              <div className='col'>
                <Field label={ListOfLoadsEditCaptions.DeliverToCity} required>
                  <StyledInputText
                    // @ts-ignore
                    value={formik.values.deliverToCity}
                    onChange={e => {
                      formik.setFieldValue('deliverToCity', e.target.value, true);
                      setListOfLoads(lol => {
                        return { ...lol, deliverToCity: e.target.value };
                      });
                    }}
                    disabled={readonly}
                    className={classNames({ 'p-invalid': isFormFieldValid('deliverToCity') })}
                  />
                  {getFormErrorMessage('deliverToCity')}
                </Field>
              </div>
              <div className='col'>
                <Field label={ListOfLoadsEditCaptions.DeliverToState} required>
                  <EditableDropdown
                    options={states}
                    optionLabel='name'
                    renderOption={s => s}
                    renderSelected={s => s}
                    renderEmptySelected={() => '-'}
                    value={listOfLoads.deliverToState}
                    // @ts-ignore
                    onChange={newValue => setListOfLoads(lol => {
                      return { ...lol, deliverToState: newValue };
                    })}
                    disabled={readonly}
                    filter
                  />
                </Field>
              </div>
            </div>
          </Panel>
          <Panel header='Load' className='w-full mt-3'>
            <div className='grid'>
              <div className='col'>
                <Field label={'Pieces'}>
                  <StyledInputText
                    // @ts-ignore
                    value={formik.values.pieces}
                    onChange={e => {
                      formik.setFieldValue('pieces', e.target.value, true);
                      setListOfLoads(lol => {
                        return { ...lol, pieces: e.target.value };
                      });
                    }}
                    disabled={readonly}
                  />
                </Field>
              </div>
              <div className='col'>
                <Field label={'Box Dims'}>
                  <StyledInputText
                    // @ts-ignore
                    value={formik.values.boxDims}
                    onChange={e => {
                      formik.setFieldValue('boxDims', e.target.value, true);
                      setListOfLoads(lol => {
                        return { ...lol, boxDims: e.target.value };
                      });
                    }}
                    disabled={readonly}
                  />
                </Field>
              </div>
              <div className='col'>
                <Field label={'Weight (lb)'}>
                  <StyledInputText
                    // @ts-ignore
                    value={formik.values.weight}
                    onChange={e => {
                      formik.setFieldValue('weight', e.target.value, true);
                      setListOfLoads(lol => {
                        return { ...lol, weight: e.target.value };
                      });
                    }}
                    disabled={readonly}
                  />
                </Field>
              </div>
            </div>
            <div className='grid'>
              <div className='col'>
                <Field label={ListOfLoadsEditCaptions.Miles} required>
                  <StyledInputNumber
                    value={formik.values.miles}
                    onChange={e => {
                      formik.setFieldValue('miles', e.value, true);
                      setListOfLoads(lol => {
                        return { ...lol, miles: e.value || 0 };
                      });
                    }}
                    disabled={readonly}
                    className={classNames({ 'p-invalid': isFormFieldValid('miles') })}
                  />
                  {getFormErrorMessage('miles')}
                </Field>
              </div>
              <div className='col'>
                <Field label={ListOfLoadsEditCaptions.Gross} required>
                  <StyledInputNumber
                    value={formik.values.gross}
                    onChange={e => {
                      formik.setFieldValue('gross', e.value, true);
                      setListOfLoads(lol => {
                        return { ...lol, gross: e.value || 0 };
                      });
                    }}
                    disabled={readonly}
                    className={classNames({ 'p-invalid': isFormFieldValid('gross') })}
                  />
                  {getFormErrorMessage('gross')}
                </Field>
              </div>
              <div className='col'>
                <Field label={ListOfLoadsEditCaptions.PriceForDriver} required>
                  <StyledInputNumber
                    value={formik.values.priceForDriver}
                    onChange={e => {
                      formik.setFieldValue('priceForDriver', e.value, true);
                      setListOfLoads(lol => {
                        return { ...lol, priceForDriver: e.value || 0 };
                      });
                    }}
                    disabled={readonly}
                    className={classNames({ 'p-invalid': isFormFieldValid('priceForDriver') })}
                    mode='decimal'
                    locale='en-US'
                    maxFractionDigits={2}
                  />
                  {getFormErrorMessage('priceForDriver')}
                </Field>
              </div>
            </div>
            <div className='grid'>
              <div className='col'>
                <Field label={ListOfLoadsEditCaptions.Week} required>
                  <LogisticsWeekCalendar
                    value={formik.values.lsNumber?.week}
                    onChange={onWeekChange}
                    className={classNames({ 'not-valid-custom': isFormFieldValid('lsNumber') }, { 'disabled-style': true })}
                    handleClick={handleCalendarClick}
                    disabled={readonly}
                    onEditOrCreateForm
                  />
                  {getFormErrorMessage('lsNumber')}
                </Field>
              </div>
              <div className='col'>
                <Field label={ListOfLoadsEditCaptions.Note}>
                  <StyledInputText
                    value={listOfLoads.note || ''}
                    onChange={e => setListOfLoads(lol => {
                      return { ...lol, note: e.target.value };
                    })}
                    disabled={readonly}
                  />
                </Field>
              </div>
              <div className='col'>
                <Field label={ListOfLoadsEditCaptions.User} required>
                  <EditableDropdown
                    options={allUsers}
                    disabled={readonly}
                    optionLabel='userName'
                    renderOption={user => `${user.firstName} ${user.lastName}`}
                    renderSelected={user => `${user?.firstName} ${user?.lastName}`}
                    renderEmptySelected={() => '-'}
                    value={formik.values.user}
                    onChange={newValue => {
                      formik.setFieldValue('user', newValue);
                      setListOfLoads(lol => {
                        return { ...lol, user: newValue };
                      });
                    }}
                    others={{
                      className: classNames({ 'p-invalid': isFormFieldValid('user') }),
                    }}
                    filter
                  />
                  {getFormErrorMessage('user')}
                </Field>
              </div>
            </div>
          </Panel>
          <Panel header='Documents' className='w-full mt-3'>
            <div className='grid'>
              <div className='col-4'>
                <Field label='Bill of landing'>
                  <LogisticsFilesUpload
                    value={listOfLoads.boLFile}
                    filePreviewWidget={filePreviewWidget}
                    onChange={newFiles =>
                      setListOfLoads(lol => {
                        return { ...lol, boLFile: newFiles };
                      })
                    }
                  />
                </Field>
              </div>
              <div className='col-4'>
                <Field label='Proof of delivered'>
                  <LogisticsFilesUpload
                    value={listOfLoads.poDFile}
                    accept='image/png, image/jpeg, application/pdf'
                    filePreviewWidget={filePreviewWidget}
                    onChange={newFiles =>
                      setListOfLoads(lol => {
                        return { ...lol, poDFile: newFiles };
                      })
                    }
                  />
                </Field>
              </div>
              <div className='col-4'>
                <Field label='Picture of the Freight'>
                  <LogisticsFilesUpload
                    value={listOfLoads.pictureFreightFile}
                    accept='image/png, image/jpeg, application/pdf'
                    filePreviewWidget={filePreviewWidget}
                    onChange={newFiles =>
                      setListOfLoads(lol => {
                        return { ...lol, pictureFreightFile: newFiles };
                      })
                    }
                  />
                </Field>
              </div>
            </div>
          </Panel>
          <div className='grid'>
            <div className='col-6 ml-auto'>
              <div className='flex justify-content-end align-items-center pt-4'>
                <div className={classNames({ 'hidden': !rights.factoring })}>
                  <span className='mr-2'>{ListOfLoadsEditCaptions.Factoring}</span>
                  <Checkbox
                    className='mr-3'
                    onChange={e => setListOfLoads(lol => {
                      return { ...lol, factoring: e.checked };
                    })}
                    checked={listOfLoads.factoring}
                  />
                </div>
                <Button label='CLOSE' className='min-w-min w-6rem h-3rem mr-3'
                        onClick={handleOnHide}></Button>
                <Button label='SAVE' className='p-button-success min-w-min w-6rem h-3rem '
                        onClick={onSubmit}></Button>
              </div>
            </div>
          </div>
          <CenteredSpinner visible={stateLoading} />
          <LogisticsFilePreview filePreviewWidget={filePreviewWidget} />
        </main>
      </LogisticsDialog>
    </>
  );
}

const getDriversFullName = (driver: Driver | undefined) => driver ? `${driver.firstName} ${driver.lastName}` : '';

export default ListOfLoadsEdit;
